import React from "react";
import { useTranslation } from "react-i18next";

const Choose = () => {
  const { t, i18n } = useTranslation();

  const features = [
    {
      images: "automate.png",
      heading: "heading-07",
      text: "text-08",
    },
    {
      images: "personalize.png",
      heading: "heading-08",
      text: "text-09",
    },
    {
      images: "visa.png",
      heading: "heading-09",
      text: "text-10",
    },
    {
      images: "hand.png",
      heading: "heading-10",
      text: "text-11",
    },
    {
      images: "support.png",
      heading: "heading-11",
      text: "text-12",
    },
    {
      images: "smiley.png",
      heading: "heading-12",
      text: "text-13",
    },
  ];
  return (
    <>
      {/* <!-- Why choose section --> */}
      <div className="w-padding">
        <div className="max-w">
          {/* title */}
          <div className="sm:flex text-center justify-center gap-2 title-spacing">
            <h1 className="heading">{t("heading-06")}</h1>
            <div className="flex justify-center sm:justify-start">
              <p className="viewpoint heading-blue">Alfa Advisor</p>
              <p className="text-xl text-primary-blue">&copy;</p>
            </div>
          </div>

          {/* <!-- contents --> */}
          <div className="grid grid-cols-1 lg:grid-cols-4 md:gap-16">
            {/* <!-- features --> */}
            <div className="col-span-3">
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 sm:gap-10 md:gap-16 items-start ">
                {features.map((feature, index) => (
                  <div className="flex items-center gap-4" key={index}>
                    <picture className=" min-w-[65px] ">
                      <img
                        src={`Images/${feature.images}`}
                        class="w-[65px]"
                        alt=""
                      />
                    </picture>
                    <div className="text-[15px] ">
                      <h1
                        className="font-extrabold text-primary-blue"
                        dangerouslySetInnerHTML={{
                          __html: t(feature.heading, {
                            interpolation: { escapeValue: false },
                          }),
                        }}
                      ></h1>
                      <p
                        className="leading-[32px] font-semibold dark:text-white"
                        dangerouslySetInnerHTML={{
                          __html: t(feature.text, {
                            interpolation: { escapeValue: false },
                          }),
                        }}
                      ></p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Other software mobile */}
              <div className="col-span-1 mt-10 mb-5 md:mb-0 md:mt-0 space-y-5 block md:hidden">
                <h1 className="font-extrabold text-primary-blue">
                  {t("heading-13")}
                </h1>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-2 place-content-center gap-7 lg:grid-cols-1">
                  <div className="flex items-center gap-2">
                    <img src="Images/check.png " alt="" />
                    <h1 className="font-bold text-[15px] dark:text-white">
                      100% automatico
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="Images/cross.png " alt="" />
                    <h1 className="font-bold text-[15px] dark:text-white">
                      {t("heading-09")}
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="Images/cross.png " alt="" />
                    <h1 className="font-bold text-[15px] dark:text-white">
                      {t("heading-10")}
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="Images/cross.png " alt="" />
                    <h1 className="font-bold text-[15px] dark:text-white">
                      {t("heading-11")}
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="Images/cross.png " alt="" />
                    <h1 className="font-bold text-[15px] dark:text-white">
                      {t("heading-12")}
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="Images/cross.png " alt="" />
                    <h1 className="font-bold text-[15px] dark:text-white">
                      {t("heading-08")}
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="Images/cross.png " alt="" />
                    <h1 className="font-bold text-[15px] dark:text-white">
                      {t("text-14")}
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="Images/cross.png " alt="" />
                    <h1 className="font-bold text-[15px] dark:text-white">
                      {t("text-15")}
                    </h1>
                  </div>
                </div>
              </div>

              {/* Statistics */}
              <div className="grid grid-cols-3 gap-5 md:gap-0 justify-items-center mt-14 mb-10 md:mb-0">
                <div className="">
                  <h1 className="font-extrabold text-[30px] sm:text-[50px] text-primary-blue">
                    {t("numero_clienti")}
                  </h1>
                  <p className="font-semibold dark:text-white text-[15px] text-center">
                    {t("customers")}
                  </p>
                </div>
                <div className="">
                  <h1 className="font-extrabold text-[30px] sm:text-[50px] text-primary-blue">
                    100%
                  </h1>
                  <p className="font-semibold dark:text-white text-[15px] text-center">
                    {t("satisfied")}
                  </p>
                </div>
                <div className="">
                  <h1 className="font-extrabold text-[30px] sm:text-[50px] text-primary-blue">
                    100%
                  </h1>
                  <p className="font-semibold dark:text-white text-[15px] text-center">
                    {t("automatic")}
                  </p>
                </div>
              </div>
            </div>

            {/* <!-- other softwares --> */}
            <div className="col-span-1 mt-10 mb-5 md:mb-0 md:mt-0 space-y-5 hidden md:block">
              <h1 className="font-extrabold text-primary-blue">
                {t("heading-13")}
              </h1>
              <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-2 place-content-center gap-7 lg:grid-cols-1">
                <div className="flex items-center gap-2">
                  <img src="Images/check.png " alt="" />
                  <h1 className="font-bold text-[15px] dark:text-white">
                    {/* 100% automatico */}
                    {t("heading-07")}
                  </h1>
                </div>
                <div className="flex items-center gap-2">
                  <img src="Images/cross.png " alt="" />
                  <h1 className="font-bold text-[15px] dark:text-white">
                    {t("heading-09")}
                  </h1>
                </div>
                <div className="flex items-center gap-2">
                  <img src="Images/cross.png " alt="" />
                  <h1 className="font-bold text-[15px] dark:text-white">
                    {t("heading-10")}
                  </h1>
                </div>
                <div className="flex items-center gap-2">
                  <img src="Images/cross.png " alt="" />
                  <h1 className="font-bold text-[15px] dark:text-white">
                    {t("heading-11")}
                  </h1>
                </div>
                <div className="flex items-center gap-2">
                  <img src="Images/cross.png " alt="" />
                  <h1 className="font-bold text-[15px] dark:text-white">
                    {t("heading-12")}
                  </h1>
                </div>
                <div className="flex items-center gap-2">
                  <img src="Images/cross.png " alt="" />
                  <h1 className="font-bold text-[15px] dark:text-white">
                    {t("heading-08")}
                  </h1>
                </div>
                <div className="flex items-center gap-2">
                  <img src="Images/cross.png " alt="" />
                  <h1 className="font-bold text-[15px] dark:text-white">
                    {t("text-14")}
                  </h1>
                </div>
                <div className="flex items-center gap-2">
                  <img src="Images/cross.png " alt="" />
                  <h1 className="font-bold text-[15px] dark:text-white">
                    {t("text-15")}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- button --> */}
          <div className="flex justify-center">
            <a href="#purchase" aria-label="">
              <div className="main-btn">
                <p
                  className="font-extrabold text-xs text-center sm:text-lg text-white"
                  dangerouslySetInnerHTML={{
                    __html: t("btn-text-03", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></p>
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7.7257C0 7.346 0.282154 7.03221 0.648229 6.98255L0.75 6.9757L15.75 6.9757C16.1642 6.9757 16.5 7.31149 16.5 7.7257C16.5 8.1054 16.2178 8.41919 15.8518 8.46885L15.75 8.4757L0.75 8.4757C0.335786 8.4757 0 8.13991 0 7.7257Z"
                    fill="#fff"
                  />
                  <path
                    d="M9.17101 2.23277C8.87749 1.94051 8.87646 1.46564 9.16872 1.17211C9.43442 0.905273 9.85103 0.880171 10.1451 1.09739L10.2294 1.16983L16.2794 7.19383C16.547 7.46032 16.5714 7.87843 16.3524 8.1725L16.2794 8.25673L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.596 9.09817 13.3028L9.17096 13.2189L14.687 7.725L9.17101 2.23277Z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Choose;
