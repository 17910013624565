import React from "react";
import { useTranslation } from "react-i18next";

const TradingInstitutional = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div id="who_we_are" className="w-padding sm:scroll-m-44 scroll-m-32">
        <div className="max-w grid items-center md:grid-cols-2 grid-cols-1 gap-6 md:gap-16">
          {/* left */}
          <div className="relative grid place-items-center">
            <picture>
              <img src="Images/big_ellipse.webp" alt="" />
            </picture>
            {/* <picture>
              <img
                src="Images/small_ellipse.webp"
                className="-top-20 md:-top-56 -left-10 z-0 absolute"
                alt=""
              />
            </picture> */}
            {/* <video section */}
            <div className=" w-full h-full grid place-items-center absolute ">
              <video
                id="my_video"
                className="video-js rounded-lg overflow-hidden max-w-[90%] object-contain border-1 border-primary-blue"
                controls
                preload="auto"
                poster="videos/poster-min.webp"
                data-setup="{}"
              >
                <source src="videos/video.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          {/* right */}
          <div className=" text-center md:text-left">
            <h1 className="heading mt-4 md:mt-0">{t("heading-02")}</h1>
            <h1 className="viewpoint text-left heading-blue inline-block">
              {t("institutional")}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradingInstitutional;
