import { useTranslation } from "react-i18next";

// Define an object with 12 links
const links = {
  link1:
    "https://shop.tradingmillimetrico.com/checkout/vps-max-4-metatrader-974",
  link2:
    "https://shop.tradingmillimetrico.com/checkout/vps-max-4-metatrader-1034",
  link3:
    "https://shop.tradingmillimetrico.com/checkout/vps-max-8-metatrader-984",
  link4:
    "https://shop.tradingmillimetrico.com/checkout/vps-max-8-metatrader-1044",
  link5: 
    "https://shop.tradingmillimetrico.com/checkout/vps-personalizzato-994",
  link6:
    "https://shop.tradingmillimetrico.com/checkout/vps-personalizzato-1054",
  link7:
    "https://shop.tradingmillimetrico.com/checkout/vps-con-ridondanza-max-4-metatrader-1004",
  link8:
    "https://shop.tradingmillimetrico.com/checkout/vps-con-ridondanza-max-4-metatrader-1064",
  link9:
    "https://shop.tradingmillimetrico.com/checkout/vps-con-ridondanza-max-8-metatrader-1014",
  link10:
    "https://shop.tradingmillimetrico.com/checkout/vps-con-ridondanza-max-8-metatrader-1074",
  link11:
    "https://shop.tradingmillimetrico.com/checkout/vps-con-ridondanza-personalizzato-1024",
  link12:
    "https://shop.tradingmillimetrico.com/checkout/vps-con-ridondanza-personalizzato-1084",
};

const PricePlans = () => {
  const { t } = useTranslation();

  return (
    <div id="vps" className="scroll-m-[128px]">
      {/* <!-- price plans --> */}
      <div className="sm:flex text-center justify-center gap-2 title-spacing">
        <h1 className="heading">Scegli il tuo server virtuale privato</h1>
        <div className="flex justify-center sm:justify-start">
          <p className="heading-blue viewpoint">(VPS)</p>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-padding lg:w-[96%] w-full  gap-7 md:gap-14 grid lg:grid-cols-3 justify-center items-start">
          {/* ITEM 1*/}
          <div>
            <div className="h-1 w-full bg-primary-blue"></div>
            <div className="bg-[#FAFAFA] p-10 shadow-xl rounded-b-3xl dark:text-black">
              <div className="">
                <p className="font-bold mt-6 text-lg md:text-[18px]">
                  VPS (max 4 MT4)
                </p>
                <h1 className="font-extrabold text-[30px] md:text-[56px] mt-6">
                  14,97€ <span className="text-sm">/ mese</span>
                </h1>
                <p className="font-bold text-[15px] mt-6"></p>
                <div className="space-y-4 mt-8 ">
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img
                        src="Images/check.png"
                        alt=""
                        className="w-6 min-w-[1.5rem]"
                      />
                    </picture>
                    <h1 className="font-bold text-[15px]">
                      PROCESSORE: RYZEN9 7900X / XEON SILVER 4309Y
                    </h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">BANDA: 1GB FLAT</h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">RAM 2GB</h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">HARD DISK: 20GB</h1>
                  </div>
                </div>

                <a
                  href={links.link1}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full mt-4"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati mensilmente
                  </p>
                </a>
                <p className="font-bold text-[15px mt-8">
                  Abbonati annualmente e risparmia
                  <br />
                  Prezzo annuale: 164,67€
                  <br />
                  Risparmia un mese di costo!
                </p>
                <a
                  href={links.link2}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full !mt-3"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati per un anno
                  </p>
                </a>

                {/* RIDONDANZA */}

                <div className="p-3 my-8 border-2 border-gray-700/40">
                  <p className="font-bold text-center">VPS CON RIDONDANZA *</p>
                  <p className="font-bold text-[15px] mt-8">
                    Aggiungi la ridondanza acquista la VPS pronta con questa
                    misura di sicurezza
                    <br />
                    <p className="mt-4">VPS (max 4 MT4) + Ridondanza</p>
                    <p className="mt-4">Prezzo mensile: 29,97€</p>
                    <a
                      href={links.link7}
                      aria-label="Abbonati mensilmente"
                      className="cursor-pointer main-btn text-center !pl-0 flex w-full mb-4 !mt-3"
                    >
                      <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                        Abbonamento mensile
                      </p>
                    </a>
                    <p className="mt-4">
                      Abbonati annualmente e risparmia un mese di costo!{" "}
                    </p>
                    <p className="mt-4">Prezzo annuale: 329,67€</p>
                    <a
                      href={links.link8}
                      aria-label="Abbonati mensilmente"
                      className="cursor-pointer main-btn text-center !pl-0 flex w-full !mt-3"
                    >
                      <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                        Abbonamento annuale
                      </p>
                    </a>
                  </p>
                  <p className="font-bold text-xs mt-8">
                    * La ridondanza nelle VPS (Virtual Private Servers) si
                    riferisce alla duplicazione di risorse hardware o alla
                    configurazione di server virtuali replicati per garantire la
                    disponibilità continua in caso di guasto o problemi. Ciò
                    assicura che un'applicazione o un sito web ospitato su una
                    VPS rimanga accessibile anche in situazioni di errore o
                    manutenzione.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* ITEM 2*/}
          <div>
            <div className="h-1 w-full bg-primary-blue"></div>
            <div className="bg-[#FAFAFA] p-10 shadow-xl rounded-b-3xl dark:text-black">
              <div className="">
                <p className="font-bold mt-6 text-lg md:text-[18px]">
                  VPS (max 8 MT4)
                </p>
                <h1 className="font-extrabold text-[30px] md:text-[56px] mt-6">
                  29,97€ <span className="text-sm">/ mese</span>
                </h1>
                <p className="font-bold text-[15px] mt-6"></p>

                <div className="space-y-4 mt-8 ">
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img
                        src="Images/check.png"
                        alt=""
                        className="w-6 min-w-[1.5rem]"
                      />
                    </picture>
                    <h1 className="font-bold text-[15px]">
                      PROCESSORE: RYZEN9 7900X / XEON SILVER 4309Y
                    </h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">BANDA: 1GB FLAT</h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">RAM 3GB</h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">HARD DISK: 40GB</h1>
                  </div>
                </div>

                {/* <!-- button --> */}
                <a
                  href={links.link3}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full mt-4"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati mensilmente
                  </p>
                </a>

                <p className="font-bold text-[15px mt-8">
                  Abbonati annualmente e risparmia
                  <br />
                  Prezzo annuale: 274,67€
                  <br />
                  Risparmia un mese di costo!
                </p>

                <a
                  href={links.link4}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full !mt-3"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati per un anno
                  </p>
                </a>

                {/* RIDONDANZA 2*/}

                <div className="p-3 my-8 border-2 border-gray-700/40">
                  <p className="font-bold text-center">VPS CON RIDONDANZA *</p>
                  <p className="font-bold text-[15px] mt-8">
                    Aggiungi la ridondanza acquista la VPS pronta con questa
                    misura di sicurezza
                    <br />
                    <p className="mt-4">VPS (max 8 MT4) + Ridondanza</p>
                    <p className="mt-4">Prezzo mensile: 59,97€</p>
                    <a
                      href={links.link9}
                      aria-label="Abbonati mensilmente"
                      className="cursor-pointer main-btn text-center !pl-0 flex w-full mb-4 !mt-3"
                    >
                      <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                        Abbonamento mensile
                      </p>
                    </a>
                    <p className="mt-4">
                      Abbonati annualmente e risparmia un mese di costo!{" "}
                    </p>
                    <p className="mt-4">Prezzo annuale: 659,67€</p>
                    <a
                      href={links.link10}
                      aria-label="Abbonati mensilmente"
                      className="cursor-pointer main-btn text-center !pl-0 flex w-full !mt-3"
                    >
                      <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                        Abbonamento annuale
                      </p>
                    </a>
                  </p>
                  <p className="font-bold text-xs mt-8">
                    * La ridondanza nelle VPS (Virtual Private Servers) si
                    riferisce alla duplicazione di risorse hardware o alla
                    configurazione di server virtuali replicati per garantire la
                    disponibilità continua in caso di guasto o problemi. Ciò
                    assicura che un'applicazione o un sito web ospitato su una
                    VPS rimanga accessibile anche in situazioni di errore o
                    manutenzione.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* ITEM 3*/}
          <div>
            <div className="h-1 w-full bg-primary-blue"></div>
            <div className="bg-[#FAFAFA] p-10 shadow-xl rounded-b-3xl dark:text-black">
              <div className="">
                <p className="font-bold mt-6 text-lg md:text-[18px]">
                  VPS (max 12 MT4)
                </p>
                <h1 className="font-extrabold text-[30px] md:text-[56px] mt-6">
                  44,97€ <span className="text-sm">/ mese</span>
                </h1>
                <p className="font-bold text-[15px] mt-6"></p>
                <div className="space-y-4 mt-8 ">
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img
                        src="Images/check.png"
                        alt=""
                        className="w-6 min-w-[1.5rem]"
                      />
                    </picture>
                    <h1 className="font-bold text-[15px]">
                      PROCESSORE: RYZEN9 7900X / XEON SILVER 4309Y
                    </h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">BANDA: 1GB FLAT</h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">RAM 4GB</h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">HARD DISK: 60GB</h1>
                  </div>
                </div>

                {/* <!-- button --> */}
                <a
                  href={links.link5}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full mt-4"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati mensilmente
                  </p>
                </a>

                <p className="font-bold text-[15px mt-8">
                  Abbonati annualmente e risparmia
                  <br />
                  Prezzo annuale: 494,67€
                  <br />
                  Risparmia un mese di costo!
                </p>

                <a
                  href={links.link6}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full !mt-3"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati per un anno
                  </p>
                </a>

                {/* RIDONDANZA 3*/}
                <div className="p-3 my-8 border-2 border-gray-700/40">
                  <p className="font-bold text-center">VPS CON RIDONDANZA *</p>
                  <p className="font-bold text-[15px] mt-8">
                    Aggiungi la ridondanza acquista la VPS pronta con questa
                    misura di sicurezza
                    <br />
                    <p className="mt-4">VPS (max 12 MT4) + Ridondanza</p>
                    <p className="mt-4">Prezzo mensile: 89,97€</p>
                    <a
                      href={links.link11}
                      aria-label="Abbonati mensilmente"
                      className="cursor-pointer main-btn text-center !pl-0 flex w-full mb-4 !mt-3"
                    >
                      <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                        Abbonamento mensile
                      </p>
                    </a>
                    <p className="mt-4">
                      Abbonati annualmente e risparmia un mese di costo!{" "}
                    </p>
                    <p className="mt-4">Prezzo annuale: 989,67€</p>
                    <a
                      href={links.link12}
                      aria-label="Abbonati mensilmente"
                      className="cursor-pointer main-btn text-center !pl-0 flex w-full !mt-3"
                    >
                      <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                        Abbonamento annuale
                      </p>
                    </a>
                  </p>
                  <p className="font-bold text-xs mt-8">
                    * La ridondanza nelle VPS (Virtual Private Servers) si
                    riferisce alla duplicazione di risorse hardware o alla
                    configurazione di server virtuali replicati per garantire la
                    disponibilità continua in caso di guasto o problemi. Ciò
                    assicura che un'applicazione o un sito web ospitato su una
                    VPS rimanga accessibile anche in situazioni di errore o
                    manutenzione.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* ITEM 4*/}
          {/* <div>
            <div className="h-1 w-full bg-[#D68D41]"></div>
            <div className="bg-[#FAFAFA] p-10 shadow-xl rounded-b-3xl dark:text-black">
              <div className="">
                <p className="font-bold mt-6 text-lg md:text-[18px]">
                  VPS CON RIDONDANZA<br/> (max 4 MT4)
                </p>
                <h1 className="font-extrabold text-[30px] md:text-[56px] mt-6">
                  29,97€ <span className="text-sm">/ mese</span>
                </h1>
                <p className="font-bold text-[15px] mt-6"></p>
                <div className="space-y-4 mt-8 ">
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">RAM 2GB</h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">STORAGE 20GB</h1>
                  </div>
                </div>
                <a
                  href={links.link7}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full mt-4"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati mensilmente
                  </p>
                </a>
                <p className="font-bold text-[15px mt-8">
                  Abbonati annualmente e risparmia
                  <br />
                  Prezzo annuale: 329,67€
                  <br />
                  Risparmia un mese di costo!
                </p>
                <a
                  href={links.link8}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full !mt-3"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati per un anno
                  </p>
                </a>   
              </div>
            </div>
          </div> */}

          {/* ITEM 5*/}
          {/* <div>
            <div className="h-1 w-full bg-[#D68D41]"></div>
            <div className="bg-[#FAFAFA] p-10 shadow-xl rounded-b-3xl dark:text-black">
              <div className="">
                <p className="font-bold mt-6 text-lg md:text-[18px]">
                  VPS CON RIDONDANZA<br/> (max 8 MT4)
                </p>
                <h1 className="font-extrabold text-[30px] md:text-[56px] mt-6">
                  59,97€ <span className="text-sm">/ mese</span>
                </h1>
                <p className="font-bold text-[15px] mt-6"></p>
                <div className="space-y-4 mt-8 ">
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">RAM 3GB</h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">STORAGE 40GB</h1>
                  </div>
                </div>
                <a
                  href={links.link9}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full mt-4"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati mensilmente
                  </p>
                </a>
                <p className="font-bold text-[15px mt-8">
                  Abbonati annualmente e risparmia
                  <br />
                  Prezzo annuale: 659,67€
                  <br />
                  Risparmia un mese di costo!
                </p>

                <a
                  href={links.link10}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full !mt-3"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati per un anno
                  </p>
                </a>   
              </div>
            </div>
          </div> */}

          {/* ITEM 6*/}
          {/* <div>
            <div className="h-1 w-full bg-[#D68D41]"></div>
            <div className="bg-[#FAFAFA] p-10 shadow-xl rounded-b-3xl dark:text-black">
              <div className="">
                <p className="font-bold mt-6 text-lg md:text-[18px]">
                  VPS CON RIDONDANZA<br/> (personalizzato)
                </p>
                <h1 className="font-extrabold text-[30px] md:text-[56px] mt-6">
                  89,97€ <span className="text-sm">/ mese</span>
                </h1>
                <p className="font-bold text-[15px] mt-6"></p>
                <div className="space-y-4 mt-8 ">
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">RAM 4GB</h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">STORAGE 60GB</h1>
                  </div>
                </div>
                <a
                  href={links.link11}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full mt-4"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati mensilmente
                  </p>
                </a>
                <p className="font-bold text-[15px mt-8">
                  Abbonati annualmente e risparmia
                  <br />
                  Prezzo annuale: 989,67€
                  <br />
                  Risparmia un mese di costo!
                </p>
                <a
                  href={links.link12}
                  aria-label="Abbonati mensilmente"
                  className="cursor-pointer main-btn text-center !pl-0 flex w-full !mt-3"
                >
                  <p className="font-extrabold w-full text-center text-sm md:text-lg text-white">
                    Abbonati per un anno
                  </p>
                </a>   
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PricePlans;
