import React from "react";
import { useTranslation } from "react-i18next";
import Subtitle from "../Common/Subtitle";

const PianoCompensi = () => {
  const { t, i18n } = useTranslation();

  const livellos = [
    {
      id: 1,
      value: "20,00%",
    },
    {
      id: 2,
      value: "10,00%",
    },
    {
      id: 3,
      value: "5,00%",
    },
    {
      id: 4,
      value: "2,50%",
    },
    {
      id: 5,
      value: "1,25%",
    },
    {
      id: 6,
      value: "0,63%",
    },
    {
      id: 7,
      value: "0,31%",
    },
    {
      id: 8,
      value: "0,16%",
    },
    {
      id: 9,
      value: "0,08%",
    },
    {
      id: 10,
      value: "0,04%",
    },
  ];

  return (
    <>
      <div
        className="w-padding text-center sm:scroll-m-44 scroll-m-32"
        id="piano"
      >
        <div className="max-w ">
          <h1 className="heading title-spacing ">
            {t("piano-compensi-title-1")}
            <span className="heading-blue inline-block viewpoint">
              {t("piano-compensi-title-2")}
            </span>
          </h1>

          {/* first section */}
          <div className="grid grid-cols-1 justify-items-center md:grid-cols-2 items-center gap-6 md:gap-16">
            {/* Left */}
            <div className="w-full text-center md:text-left">
              <Subtitle firstText={t("commissioni")} />
              <div className="font-semibold dark:text-white text-[15px] text-justify space-y-4">
                <p className="">{t("commissioni-p1")}</p>

                <div className="space-y-1/2 ">
                  {livellos.map((item, index) => (
                    <div className="flex gap-4" key={index}>
                      <span className="min-w-[14px]">{item.id}</span>
                      <span>{t("livello")}</span>
                      <span>-&gt;</span>
                      <span>{item.value}</span>
                    </div>
                  ))}
                </div>

                <p>
                  <p className="">{t("commissioni-p2")}</p>
                  <br />
                  {/* <p className="">{t("commissioni-p3")}</p> */}
                </p>
              </div>
            </div>

            {/* Right */}
            <div>
              <picture className="">
                <img
                  src="Images/piano-right.png"
                  alt=""
                  className="max-h-[30rem]"
                />
              </picture>
            </div>
          </div>

          {/* second section */}
          <div className="grid grid-cols-1 justify-items-center md:grid-cols-2 items-center gap-6 md:gap-16 mt-10 md:mt-0">
            {/* Left */}
            <div className="">
              <picture>
                <img
                  src="Images/bonus-left.png"
                  alt=""
                  className="max-h-[620px]"
                />
              </picture>
            </div>

            {/* Right */}
            <div className="w-full text-left space-y-6 order-[-1] md:order-2">
              <div className="text-center md:text-left">
                <Subtitle firstText={t("bonus")} secondText={t("carriera")} />
              </div>

              <div className="font-semibold dark:text-white text-[15px] text-justify space-y-4">
                <p className="">{t("bonus-carriera-paragraph-1")}</p>
                <ul className="space-y-2">
                  <li>{t("bonus-carriera-list-1")}</li>
                  <li>{t("bonus-carriera-list-2")}</li>
                  <li>{t("bonus-carriera-list-3")}</li>
                  <li>{t("bonus-carriera-list-4")}</li>
                  <li>{t("bonus-carriera-list-5")}</li>
                </ul>
                <p>{t("bonus-carriera-paragraph-2")}</p>
                <p>{t("bonus-carriera-paragraph-3")}</p>
                {/* <p>{t("bonus-carriera-paragraph-4")}</p> */}
              </div>
            </div>
          </div>

          {/* third section */}
          <div className="grid grid-cols-1 justify-items-center md:grid-cols-2 items-center gap-6 md:gap-16 mt-10 md:mt-0">
            {/* Left */}
            <div className="w-full text-left space-y-6">
              <div className="text-center md:text-left">
                <Subtitle firstText={t("bonus")} secondText={t("rapidita")} />
              </div>
              <div className="font-semibold dark:text-white text-[15px] text-justify space-y-4">
                <p className="">{t("rapidita-paragraph")}</p>
                <ul className="space-y-2">
                  <li>{t("rapidita-list-1")}</li>
                  <li>{t("rapidita-list-2")}</li>
                  <li>{t("rapidita-list-3")}</li>
                </ul>
                <p className="">{t("rapidita-paragraph-2")}</p>
                <p className="">{t("rapidita-paragraph-3")}</p>
                {/* <p className="">{t("rapidita-paragraph-4")}</p> */}
              </div>
            </div>

            {/* Right */}
            <div>
              <picture>
                <img
                  src="Images/bonus-right.png"
                  alt=""
                  className="max-h-[920px]"
                />
              </picture>
            </div>
          </div>

          {/* <!-- button --> */}
          <a aria-label="" href="#purchase" className="mt-10 block md:mt-0">
            <div className="main-btn">
              <p className="font-extrabold text-xs md:text-lg text-white text-left uppercase">
                {t("commissions-button")}
              </p>
              <svg
                className="h-5 w-5"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 7.7257C0 7.346 0.282154 7.03221 0.648229 6.98255L0.75 6.9757L15.75 6.9757C16.1642 6.9757 16.5 7.31149 16.5 7.7257C16.5 8.1054 16.2178 8.41919 15.8518 8.46885L15.75 8.4757L0.75 8.4757C0.335786 8.4757 0 8.13991 0 7.7257Z"
                  fill="#fff"
                />
                <path
                  d="M9.17101 2.23277C8.87749 1.94051 8.87646 1.46564 9.16872 1.17211C9.43442 0.905273 9.85103 0.880171 10.1451 1.09739L10.2294 1.16983L16.2794 7.19383C16.547 7.46032 16.5714 7.87843 16.3524 8.1725L16.2794 8.25673L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.596 9.09817 13.3028L9.17096 13.2189L14.687 7.725L9.17101 2.23277Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default PianoCompensi;
