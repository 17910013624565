import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';

const LinkButton = () => {
    const [showLinks, setShowLinks] = useState(false);

    const links = [
        { url: 'https://t.me/TradingMillimetricoSupport', label: 'Telegram Trading Support' },
        { url: 'https://wa.me/3516925247', label: 'WhatsApp Contact 1' },
        { url: 'https://t.me/InfoTradingMillimetrico', label: 'Telegram Info Trading' },
        { url: 'https://wa.me/691373862', label: 'WhatsApp Contact 2' }
    ];

    return (
        <div className="fixed bottom-4 right-4 z-50">
            <button onClick={() => setShowLinks(!showLinks)} className="text-primary-blue text-4xl hover:text-primary-blue/80">
                <FontAwesomeIcon icon={faCommentDots} />
            </button>
            {showLinks && (
                <div className="bg-white p-4 rounded-lg shadow-lg absolute bottom-full mb-2 right-0 z-50">
                    {links.map((link, index) => (
                        <div key={index} className="my-2 w-64">
                            <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-primary-blue hover:text-primary-dark_body transition duration-300 ease-in-out">
                                {link.label}
                            </a>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
    
    
};

export default LinkButton;

// {showLinks && (
//     <div className="bg-white p-4 mt-2 rounded-lg shadow-lg w-40 absolute right-0 z-50">
//         {links.map((link, index) => (
//             <div key={index} className="my-2">
//                 <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out">
//                     {link.label}
//                 </a>
//             </div>
//         ))}
//     </div>
// )}

