import React from "react";
import { useTranslation } from "react-i18next";
import QuestionFormat from "../Common/QuestionFormat";

const Faq = () => {
  const { t } = useTranslation();

  const Sdata = [

    {
      id: 1,
      ques: t("faq-01"),
      ans: t("accordion-01"),
    },
    {
      id: 2,
      ques: t("faq-02"),
      ans: t("accordion-02"),
    },
    {
      id: 3,
      ques: t("faq-03"),
      ans: t("accordion-03"),
    },

    {
      id: 4,
      ques: t("faq-04"),
      ans: t("accordion-04"),
    },
    {
      id: 5,
      ques: t("faq-05"),
      ans: t("accordion-05"),
    },
    {
      id: 6,
      ques: t("faq-06"),
      ans: t("accordion-06"),
    },
    {
      id: 7,
      ques: t("faq-07"),
      ans: t("accordion-07"),
    },
    {
      id: 8,
      ques: t("faq-08"),
      ans: t("accordion-08"),
    },
    {
      id: 9,
      ques: t("faq-09"),
      ans: t("accordion-09"),
    },
    {
      id: 10,
      ques: t("faq-10"),
      ans: t("accordion-10"),
    },
    {
      id: 11,
      ques: t("faq-11"),
      ans: t("accordion-11"),
    },
    {
      id: 12,
      ques: t("faq-12"),
      ans: t("accordion-12"),
    },
    {
      id: 13,
      ques: t("faq-13"),
      ans: t("accordion-13"),
    },
    {
      id: 14,
      ques: t("faq-14"),
      ans: t("accordion-14"),
    },
  ];

  return (
    <>
      {/* <!-- faq --> */}
      <div id="faq" className="w-padding mt-2 sm:scroll-m-44 scroll-m-32">
        <div className="max-w">
          <h1 className="heading text-center title-spacing">
            {t("question")}{" "}
            <span className="heading-blue viewpoint"> {t("frequent")} </span>
          </h1>
          <div className="md:px-10 md:pb-10">
            {Sdata.map((val, index) => {
              return <QuestionFormat key={index} {...val} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
