import React from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../ThemeContext";
const Company = () => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const { t, i18n } = useTranslation();

  const companies = [
    {
      image: "_wipo.png",
      text: "pic1",
    },
    {
      image: "SIAE.png",
      text: "pic2",
    },
    {
      image: "third.png",
      text: "pic3",
    },
  ];
  return (
    <>
      {/* companies*/}
      <div className="w-padding ">
        <div className="max-w">
          <div className="grid lg:grid-cols-3 items-center gap-8  md:grid-cols-2 justify-items-center grid-cosl-1">



                {/* wipo */}
                <div className="flex flex-col items-center gap-2 md:gap-4">
                  <img
                    className="h-[130px] object-cover"
                    src={`Images/_wipo.png`}
                    alt=""
                  />
                  <p
                    className="font-semibold dark:text-white text-[15px] text-center"
                    dangerouslySetInnerHTML={{
                      __html: t("pic1", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </div>

                {/* siae */}
                <div className="flex flex-col items-center gap-2 md:gap-4">
                  <img
                    className="h-[130px] object-cover -mb-4 lg:mb-0"
                    src={`Images/SIAE.png`}
                    alt=""
                  />
                  <p
                    className="font-semibold dark:text-white text-[15px] text-center"
                    dangerouslySetInnerHTML={{
                      __html: t("pic2", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </div>

                {/* third */}
                <div className="flex flex-col items-center gap-2 md:gap-4">
                  <img
                    className="h-[130px] object-cover"
                    src={`Images/third.png`}
                    alt=""
                  />
                  <p
                    className="font-semibold dark:text-white text-[15px] text-center"
                    dangerouslySetInnerHTML={{
                      __html: t("pic3", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </div>


            {/* {companies.map((company) => {
              return (
                <div className="flex flex-col items-center gap-2 md:gap-4">
                  <img
                    className="h-[130px] object-cover"
                    src={`Images/${company.image}`}
                    alt=""
                  />
                  <p
                    className="font-semibold dark:text-white text-[15px] text-center"
                    dangerouslySetInnerHTML={{
                      __html: t(company.text, {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </div>
              );
            })} */}
          </div>
          <p
            className="font-semibold dark:text-white text-[15px] text-center mt-14"
            dangerouslySetInnerHTML={{
              __html: t("text-01", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
          <div className="mt-16 grid grid-cols-3">
            <div className="bg-primary-blue h-1 w-full"></div>
            <div className="bg-[#B2B2B2] col-span-2 h-1 w-full"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
