import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BarChart from "./BarChart";

const CompoundIntrest = () => {
  const [output, setOutput] = useState(190);
  const [capitalValue, setcapitalValue] = useState(1200);
  const [monthValue, setMonthValue] = useState(12);
  const [profitvalue, setProfitValue] = useState(10);
  const [barData, setBarData] = useState({ label: [], data: [] });

  const ChangeCapitalInput = (val) => {
    val < 0 ? setcapitalValue(100) : setcapitalValue(val);
  };
  const ChangeMonthInput = (val) => {
    val < 0 ? setMonthValue(1) : setMonthValue(val);
  };
  const ChangeProfitInput = (val) => {
    val < 0 || val > 10 ? setProfitValue(1) : setProfitValue(val);
  };

  const ChangeCapital = (symbol) => {
    symbol === "-" && capitalValue !== 0
      ? setcapitalValue(capitalValue - 100)
      : setcapitalValue(capitalValue + 100);
    capitalValue < 100 && setcapitalValue(100);
  };

  const ChangeMonth = (symbol) => {
    symbol === "-" && !monthValue == 0
      ? setMonthValue(monthValue - 1)
      : setMonthValue(monthValue + 1);
    monthValue < 1 && setMonthValue(1);
  };
  const Calculate = (capitalValue, monthValue, profitvalue) => {
    let month_arr = [];
    let month_no = [];
    const capital = capitalValue,
      month = monthValue;
    var profit_percentage = profitvalue;
    var factor = 1 + profit_percentage / 100;
    let total = capitalValue;
    for (let i = 1; i <= month; i++) {
      total *= factor;
      setOutput(
        (total - capital)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .replace(".", ",")
          .replace(",", ".")
      );
      let newMonth = total - capital;
      month_arr.push(newMonth.toFixed(2));
      month_no.push(i);
    }
    setBarData({
      ...barData,
      label: month_no,
      data: month_arr,
    });
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    Calculate(capitalValue, monthValue, profitvalue);
  }, [capitalValue, monthValue, profitvalue]);

  return (
    <>
      {/* <!-- compound intrest calculate section --> */}
      <div
        id="computer"
        className="w-padding sm:scroll-m-44 scroll-m-32 text-center"
      >
        <div className="max-w">
          <div className="title-spacing scale-75">
            <h1 className="heading ">{t("calculator")}</h1>
            <h1 className="heading-blue viewpoint inline-block ">
              {t("heading-05")}
            </h1>
          </div>

          {/* <!-- input fields --> */}
          <div className=" md:flex gap-3 sm:gap-10 justify-center items-end">
            {/* <!-- capital --> */}
            <div className="">
              <h1
                className="font-extrabold text-[20px] md:text-[22px] text-left text-primary-blue"
                htmlFor=""
              >
                {t("capital")}
              </h1>
              <div className="flex items-center bg-[#FAFAFA] rounded-[10px] shadow-md py-6 px-11 text-black">
                <svg
                  //    onClick="decreaseCapital();Calculate(); graph();"
                  onClick={() => {
                    ChangeCapital("-");
                  }}
                  className="h-10 w-10 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="#38B6FF"
                    d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h368c4.4 0 8 3.6 8 8v48z"
                  />
                </svg>
                <label htmlFor=""></label>
                <input
                  //    onInput="Calculate(); graph(); this.value = Math.abs(this.value)"
                  onChange={(e) => ChangeCapitalInput(e.target.value)}
                  type="number"
                  value={capitalValue}
                  min="0"
                  step="100"
                  name=""
                  placeholder=""
                  id="capital"
                  className="text-center outline-none font-extrabold text-[32px] bg-[#FAFAFA] px-6 w-[200px] rounded-md py-2"
                />

                <svg
                  //    onClick="increaseCapital(); Calculate(); graph();"
                  onClick={() => ChangeCapital("+")}
                  className="h-12 w-12 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#38B6FF"
                    d="M2 8a6 6 0 1 1 12 0A6 6 0 0 1 2 8Zm6-3a.5.5 0 0 0-.5.5v2h-2a.5.5 0 0 0 0 1h2v2a.5.5 0 0 0 1 0v-2h2a.5.5 0 0 0 0-1h-2v-2A.5.5 0 0 0 8 5Z"
                  />
                </svg>
              </div>
            </div>
            {/* <!-- months --> */}
            <div className="md:mt-0 mt-3">
              <h1
                className="font-extrabold text-[20px] md:text-[22px] text-left text-primary-blue"
                htmlFor=""
              >
                {t("months")}
              </h1>
              <div className="flex items-center bg-[#FAFAFA] rounded-[10px] shadow-md py-6 px-11 text-black">
                <svg
                  //    onClick="decreaseMonth();Calculate(); graph();"
                  onClick={() => ChangeMonth("-")}
                  className="h-10 w-10 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="#38B6FF"
                    d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h368c4.4 0 8 3.6 8 8v48z"
                  />
                </svg>
                <label htmlFor=""></label>

                <input
                  type="number"
                  name=""
                  min="0"
                  placeholder=""
                  onChange={(e) => ChangeMonthInput(e.target.value)}
                  //onInput="Calculate(); graph();this.value==0||this.value<0?this.value=null:this.value"
                  id="month"
                  value={monthValue}
                  className="text-center outline-none font-extrabold text-[32px] bg-[#FAFAFA] px-6 w-[200px] rounded-md py-2"
                />
                <svg
                  //    onClick="increaseMonth();Calculate(); graph();"
                  onClick={() => ChangeMonth("+")}
                  className="h-12 w-12 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#38B6FF"
                    d="M2 8a6 6 0 1 1 12 0A6 6 0 0 1 2 8Zm6-3a.5.5 0 0 0-.5.5v2h-2a.5.5 0 0 0 0 1h2v2a.5.5 0 0 0 1 0v-2h2a.5.5 0 0 0 0-1h-2v-2A.5.5 0 0 0 8 5Z"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* <!-- Profitto mensile --> */}
          <div className="md:flex justify-center items-center mt-14 gap-3 max-w-5xl mx-auto">
            <div className="flex items-center gap-6">
              <label
                className="font-extrabold text-[20px] md:text-[22px] text-left text-primary-blue"
                htmlFor=""
              >
                {t("monthly profit")}
              </label>

              <input
                type="number"
                min="0"
                max="10"
                name=""
                value={profitvalue}
                placeholder=""
                id="output"
                onChange={(e) => ChangeProfitInput(e.target.value)}
                //  onInput="this.value>10?this.value=10:this.value; inputChanged(this.value); Calculate(); graph();this.value = Math.abs(this.value); "
                className="border border-primary-blue text-gray-2 dark:border-none md:text-[22px] text-[20px] w-[80px] pl-4 pr-1 rounded-md py-2 text-black"
              />
            </div>
            {/* <!-- slider --> */}
            <label htmlFor=""></label>
            <input
              className="cursor-pointer w-full md:w-auto mt-3 md:mt-0 flex-grow"
              id="slider"
              placeholder=""
              type="range"
              onChange={(e) => ChangeProfitInput(e.target.value)}
              min="1"
              max="10"
              step="1"
              value={profitvalue}
              //     onInput="sliderChange(this.value);Calculate(); graph();"

              data-orientation="vertical"
            />
          </div>

          <div className="mt-5 border dark:border-none border-primary-blue max-w-5xl mx-auto">
            {/* <!-- Profit output --> */}
            <div className="bg-[#FAFAFA] rounded-[10px] shadow-md py-6 px-11">
              <h1
                className="font-extrabold text-[16px] md:text-[22px] text-left text-primary-blue"
                htmlFor=""
              >
                {t("profit-yearly")}
              </h1>
              <div className="text-left outline-none font-extrabold text-[22px] bg-[#FAFAFA]  rounded-md py-2 text-black">
                {output}
              </div>
            </div>
          </div>

          {/* <!-- bar diagram --> */}
          {/* <div className="mt-10 md:mt-20">
            <canvas id="myChart"></canvas>
          </div> */}
          <div className="md:mt-20 mt-5 max-w-5xl mx-auto">
            <BarChart label={barData.label} data={barData.data} />
          </div>
          <div className="h-0.5 w-full bg-primary-blue mt-20"></div>
        </div>
      </div>
    </>
  );
};

export default CompoundIntrest;
