import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { ThemeContext } from "../../ThemeContext";
import "flowbite";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
// import "swiper/css/scrollbar";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectCreative,
} from "swiper/modules";

const Hero = () => {
  const { t, i18n } = useTranslation();
  const { theme } = React.useContext(ThemeContext);
  const language = i18n.language;

  const [images, setImages] = useState({
    en: {
      light: [
        "/Images/carousel/1 - en (2).jpg",
        "/Images/carousel/2 - en (2).jpg",
        "/Images/carousel/3 - en (2).jpg",
      ],
      dark: [
        "/Images/carousel/1 - en.jpg",
        "/Images/carousel/2 - en.jpg",
        "/Images/carousel/3 - en.jpg",
      ],
    },
    es: {
      light: [
        "/Images/carousel/1 - es (2).jpg",
        "/Images/carousel/2 - es (2).jpg",
        "/Images/carousel/3 - es (2).jpg",
      ],
      dark: [
        "/Images/carousel/1 - es.jpg",
        "/Images/carousel/2 - es.jpg",
        "/Images/carousel/3 - es.jpg",
      ],
    },
    it: {
      light: [
        "/Images/carousel/1 - it (2).jpg",
        "/Images/carousel/2 - it (2).jpg",
        "/Images/carousel/3 - it (2).jpg",
      ],
      dark: [
        "/Images/carousel/1 - it.jpg",
        "/Images/carousel/2 - it.jpg",
        "/Images/carousel/3 - it.jpg",
      ],
    },
  });

  const links = ["#purchase", "#prop", "#vps"];

  const getImagePaths = () => {
    const languageImages = images[language];
    if (!languageImages) {
      return images.en[theme];
    }
    return languageImages[theme];
  };

  const imagePaths = getImagePaths();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div id="home" className="w-padding mt-6 sm:scroll-m-44 scroll-m-32 ">
        <Swiper
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          slidesPerView={1}
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            Autoplay,
            EffectCreative,
          ]}
          direction="horizontal"
          navigation={!isMobile}
          pagination={!isMobile && { clickable: true }}
          scrollbar={{ draggable: true }}
          autoplay={{ delay: 4000 }}
          className="rounded-xl"
        >
          {imagePaths.map((path, index) => (
            <SwiperSlide>
              <a key={index} data-carousel-item href={links[index]}>
                <img src={path} alt={`Carousel Image ${index + 1}`} />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Hero;
