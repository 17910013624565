import { useTranslation } from "react-i18next";

const PricePlans = () => {
  const { t } = useTranslation();

  return (
    <div id="purchase" className="scroll-m-[128px]">
      {/* <!-- price plans --> */}
      <div className="sm:flex text-center justify-center gap-2 title-spacing">
        {/* <h1 className="heading"> {t("heading-16")}</h1> */}
        <div className="flex justify-center sm:justify-start">
          <p className="heading-blue viewpoint">Alfa Advisor</p>
          <p className="text-xl text-primary-blue">&copy;</p>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-padding lg:w-[70%] w-full grid justify-center items-start">
          <div className="">
            <div className="h-1 w-full bg-primary-blue"></div>
            <div className="card-gradient shadow-xl text-white rounded-b-3xl">
              {/* <div className="flex justify-end">
                <div className="bg-[#FFB470] rounded-b-xl text-center text-bold p-[15px]">
                  {t("best")}
                </div>
              </div> */}
              <div className="px-10 pb-10 pt-[9px]">
                <p className="font-bold text-[18px] dark:text-white">
                  {t("unlimited")}
                </p>
                <h1 className="font-extrabold text-[30px] md:text-[56px] mt-6">
                  997,00€
                </h1>

                {/* 
                <p className="font-bold text-[15px] dark:text-white mt-6">
                  INCLUSA VPS PER 6 MESI
                </p> */}
                <div className="space-y-4 mt-8">
                  {/* <!-- single item --> */}
                  {/* <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">
                      INCLUSA VPS PER 6 MESI
                    </h1>
                  </div> */}
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">{t("pl1")} </h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">{t("pl2")} </h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">{t("zero")} </h1>
                  </div>
                  {/* <!-- single item --> */}
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">{t("pl3")} </h1>
                  </div>
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">{t("pl4")} </h1>
                  </div>
                  {/* <!-- single item --> */}
                  <div className="flex gap-2 items-center">
                    <picture>
                      <img src="Images/check.png" alt="" className="w-6" />
                    </picture>
                    <h1 className="font-bold text-[15px]">{t("pl5")} </h1>
                  </div>
                  {/* <!-- single item --> */}
                </div>
                <p className="font-semibold text-xs mt-6">
                  {/* Alfa Advisor©  */}
                  {t("card_text")}
                </p>
                <p className="font-semibold text-xs mt-2">{t("card_text_2")}</p>

                {/* <!-- button --> */}
                <a
                  href="https://shop.tradingmillimetrico.com/checkout/licenza-illimitata-864?aff="
                  aria-label=""
                >
                  <div className="cursor-pointer md:mt-12 mt-5 mb-[2px] hover-animation hover:scale-110 rounded-md py-4 gap-7 pr-4 items-center !bg-white text-center !pl-0 flex w-full">
                    <p className="font-extrabold w-full text-center text-sm md:text-lg text-primary-blue">
                      {t("card_button_02")}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricePlans;
