import React from "react";
import { useTranslation } from "react-i18next";

const Founder = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* expert advisor */}

      <div className="w-padding text-center ">
        <div className="max-w ">
          <div className="title-spacing">
            <h1 className="heading inline">{t("founder-title-1")}</h1>
            <span className="heading-blue inline-block viewpoint">
              {t("founder-title-2")}
            </span>{" "}&nbsp;
            <h1
              className="heading inline"
              dangerouslySetInnerHTML={{
                __html: t("founder-title-3", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></h1>
          </div>
          <div className="grid grid-cols-1 justify-items-center md:grid-cols-2 items-center">
            {/* Left */}
            <div>
              <picture className="-m-10">
                <img src="Images/founder.png" alt="" />
              </picture>
            </div>

            {/* Right */}
            <div className="w-full text-left ">
              {/* <p className="font-bold dark:text-white text-xl ">
                <span className="text-primary-blue">Katia Grillo, </span>{" "}
                {t("founder-title-2")}
              </p> */}

              <p
                className="font-semibold dark:text-white text-[15px] mt-10 lg:mt-0 text-justify"
                dangerouslySetInnerHTML={{
                  __html: t("founder-paragraph", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>

                {/* Katia Grillo, avendo avuto sempre la passione per l'online,
                inizio' gia' da molto giovane ad informarsi sul trading e fare
                corsi di formazione. Fin da piccola, Katia, fece molti sacrifici
                nella propria vita, ma tutto cio' che guadagnava lo perdeva
                costantemente a causa di sistemi online truffaldini che
                promettevano l'indipendenza finanziaria, cosi', dopo diverse
                delusioni, inizio' a desiderare di realizzare un sistema unico,
                che funzionasse davvero e che non le facesse perdere soldi in
                continuazione. E' sempre stata una persona molto determinata, ha
                cercato di arrivare sempre piu' in alto senza mai arrendersi ed
                accontentarsi, cosi' un giorno, partecipando ad un evento di
                formazione, incontro' Giuseppe Eros Lana, un ragazzo poco piu'
                grande di lei, con grandi ambizioni che intraprese fin da molto
                giovane la sua vita lavorativa. Giuseppe cambio' molti lavori
                nel corso della propria vita, ma sempre desideroso di avere una
                propria azienda, cosi', un giorno, ando' ad un evento di network
                marketing al quale non era mai stato, si innamoro' della
                tipologia di business, dell'ambiente e inizio' cosi' il suo
                percorso formativo in questa nuova azienda. Mesi dopo, conobbe
                il trading online, inizio' ad interessarsi ed affascinarsi a
                questo nuovo settore seppur inizialmente con scetticismo e
                sospetto, del quale, tra fallimenti e successi, se ne e'
                innamorato in brevissimo tempo e decise di partecipare ad un
                corso di formazione dove incontro' Katia. I due, si trovarono
                sintonizzati fin dal primo momento, iniziarono a discutere di
                sogni, i quali divennero obiettivi, concretizzati
                successivamente in progetti e fu cosi' che iniziarono a lavorare
                su Alfa Advisor<sup>©</sup>. Oggi, a distanza di anni, Katia e
                Giuseppe sono una splendida coppia nonche’ imprenditori e
                traders di successo, i quali, grazie alle loro competenze unite
                alla loro leadership, hanno dato vita ad un impero di fama
                mondiale chiamato Trading Millimetrico<sup>®</sup>. */}
   

              {/* <a
                className="font-semibold dark:text-white text-[15px] flex items-center gap-2 text-primary-blue"
                href=""
              >
                {t("founder-link")}
                <svg
                  className="h-4 w-4 rotate-90"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7.7257C0 7.346 0.282154 7.03221 0.648229 6.98255L0.75 6.9757L15.75 6.9757C16.1642 6.9757 16.5 7.31149 16.5 7.7257C16.5 8.1054 16.2178 8.41919 15.8518 8.46885L15.75 8.4757L0.75 8.4757C0.335786 8.4757 0 8.13991 0 7.7257Z"
                    fill="currentColor"
                  />
                  <path
                    d="M9.17101 2.23277C8.87749 1.94051 8.87646 1.46564 9.16872 1.17211C9.43442 0.905273 9.85103 0.880171 10.1451 1.09739L10.2294 1.16983L16.2794 7.19383C16.547 7.46032 16.5714 7.87843 16.3524 8.1725L16.2794 8.25673L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.596 9.09817 13.3028L9.17096 13.2189L14.687 7.725L9.17101 2.23277Z"
                    fill="currentColor"
                  />
                </svg>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Founder;
