import React from "react";

const Subtitle = ({ firstText, secondText }) => {
  return (
    <>
      {firstText && secondText ? (
        <h1 className="lg:text-3xl text-2xl dark:text-white font-bold leading-[39px] lg:leading-[50px]">
          {firstText}
          <h1 className="heading-blue-sm inline-block relative">
            {secondText}
            <div className="viewpoint w-full h-[30px] absolute top-[-48px]"></div>
          </h1>
        </h1>
      ) : (
        <h1 className="heading-blue-sm inline-block relative">
          {firstText}
          <div className="viewpoint w-full h-[30px] absolute top-[-48px]"></div>
        </h1>
      )}
    </>
  );
};

export default Subtitle;
