import React from "react";

const MarqueItem = ({ title, Value, MarqueCss, arrow }) => {
  return (
    <>
      <p className="currency box">
        {title}
        <span className="">=</span>
        <span className={`${MarqueCss}`}>
          <span>{Value}</span>
          <span>{arrow}</span>
        </span>
      </p>
    </>
  );
};

export default MarqueItem;
