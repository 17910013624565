import React from "react";
import { useTranslation } from "react-i18next";

const ExpertAdvisor = () => {
  const { t, i18n } = useTranslation();

  const lists = [
    {
      image: "cylinder",
      text: "text-02",
    },
    {
      image: "flat-half-donut",
      text: "text-03",
    },
    {
      image: "octagon",
      text: "text-04",
    },
    {
      image: "double-circle",
      text: "text-05",
    },
    {
      image: "basic-gem",
      text: "text-06",
    },
    {
      image: "l-shape",
      text: "text-07",
    },
  ];

  return (
    <>
      {/* expert advisor */}
      <div className="w-padding text-center">
        <div className="max-w">
          <div className="title-spacing">
            <h1 className="heading">{t("heading-03")}</h1>
            <h1 className="heading-blue inline-block viewpoint">
              {t("heading-04")}
            </h1>
          </div>

          <div className="grid grid-cols-1 place-items-center md:grid-cols-2 items-center gap-6 md:gap-16">
            {/* <!-- left --> */}
            <div className="space-y-7 order-2 md:order-1">
              {/* <!-- single item --> */}
              {lists.map((list, index) => (
                <div
                  className="flex items-center gap-2"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  key={index}
                >
                  <picture className="min-w-[40px]">
                    <img src={`Images/${list.image}.png`} alt="" className="" />
                  </picture>
                  <p className="dark:text-white font-bold text-[15px] text-left">
                    {t(list.text)}
                  </p>
                </div>
              ))}
            </div>
            {/* <!-- right --> */}
            <div className="relative order-1 md:order-2 mt-5 md:mt-0">
              <picture>
                <img src="Images/big_ellipse.webp" alt="" />
              </picture>
              <picture>
                <img
                  loading="lazy"
                  src="Images/alfa.webp"
                  className="absolute h-[80%] md:h-full md:w- w-[80%] top-3 left-16"
                  alt="alfa"
                />
              </picture>
              <picture>
                <img
                  src="Images/small_ellipse.webp"
                  className="absolute -top-56"
                  alt=""
                />
              </picture>
            </div>
          </div>

          {/* <!-- button --> */}
          <a aria-label="" href="#purchase">
            <div className="main-btn ">
              <p
                className="font-extrabold text-xs md:text-lg text-white text-center"
                dangerouslySetInnerHTML={{
                  __html: t("btn-text-02", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>
              <svg
                className="h-5 w-5"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 7.7257C0 7.346 0.282154 7.03221 0.648229 6.98255L0.75 6.9757L15.75 6.9757C16.1642 6.9757 16.5 7.31149 16.5 7.7257C16.5 8.1054 16.2178 8.41919 15.8518 8.46885L15.75 8.4757L0.75 8.4757C0.335786 8.4757 0 8.13991 0 7.7257Z"
                  fill="#fff"
                />
                <path
                  d="M9.17101 2.23277C8.87749 1.94051 8.87646 1.46564 9.16872 1.17211C9.43442 0.905273 9.85103 0.880171 10.1451 1.09739L10.2294 1.16983L16.2794 7.19383C16.547 7.46032 16.5714 7.87843 16.3524 8.1725L16.2794 8.25673L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.596 9.09817 13.3028L9.17096 13.2189L14.687 7.725L9.17101 2.23277Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default ExpertAdvisor;
