import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import i18next from "i18next";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../ThemeContext";

const Modal = () => {
  const disclaimer = JSON.parse(sessionStorage.getItem("disclaimer"));
  const { theme, setTheme } = React.useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const handleAccept = () => {
    sessionStorage.setItem("disclaimer", JSON.stringify(true));
    navigate(0);
  };

  useEffect(() => {
    if (!disclaimer) {
      document.body.style.overflow = "hidden";

      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0 });
      }, [10]);
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [disclaimer, location]);

  const countries = [
    {
      label: "Italy",
      src: "Images/flags/it.png",
      link: " ",
      value: "IT",
    },
    {
      label: "Spain",
      src: "Images/flags/es.png",
      link: " ",
      value: "ES",
    },
    {
      label: "English",
      src: "Images/flags/en.png",
      link: " ",
      value: "EN",
    },
  ];

  const selectedFlag = i18n.language;

  const [country, setCountry] = React.useState(
    `Images/flags/${selectedFlag}.png`
  );
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    const selected = event.target.value.slice(13, 15);
    setCountry(event.target.value);
    i18next.changeLanguage(selected);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const langStyle = {
    menuProps: {
      sx: {
        // "&& .Mui-selected": {
        //   backgroundColor: "#38b6ff",
        // },
        "&& .MuiList-root": {
          backgroundColor: "#04153f",
        },
      },
    },
    sx: {
      color: "white",
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
      },
      ".MuiSvgIcon-root ": {
        fill: "white !important",
      },
    },
    menuItem: { backgroundColor: "#04153f" },
  };

  return (
    <div
      class={`bg-gray-700/50 w-full h-[100vh] backdrop-blur-xl backdrop-filter absolute inset-0 z-[100]  place-items-center  ${
        !disclaimer ? "grid" : "hidden"
      }`}
    >
      <div className="bg-white dark:bg-primary-dark_body md:rounded-xl w-full max-w-[100%] md:max-w-[90%] p-3 max-h-[100vh] md:max-h-[90vh] overflow-y-auto pb-36 lg:pb-4">
        <div className="flex items-center justify-between relative">
          {/* country select */}
          <FormControl sx={{ minWidth: "20px", padding: "0" }}>
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={country}
              name="country"
              onChange={handleChange}
              inputProps={{
                id: "open-select",
              }}
              MenuProps={theme === "dark" && langStyle.menuProps}
              sx={theme === "dark" && langStyle.sx}
            >
              {countries.map((option, key) => (
                <MenuItem
                  name={"l"}
                  value={option.src}
                  key={key}
                  sx={theme === "dark" && langStyle.menuItem}
                >
                  <img
                    width="30px"
                    height="30px"
                    src={option.src}
                    alt={option.label}
                  />{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div
            // onClick={setTheme(theme === "dark" ? "light" : "dark")}
            onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
            id="dark"
            className={`p-1 bg-primary-blue w-10 md:w-14 rounded-full cursor-pointer `}
          >
            <div className="rounded-full hover-animation cursor-pointer transform">
              {/* sun */}
              <div
                id="sun"
                className={`bg-white dark:bg-primary-dark_body hover-animation rounded-full w-4 h-4 md:w-6 md:h-6 flex justify-center items-center relative group ${
                  theme === "dark" ? "translate-x-[20px]" : ""
                }`}
              >
                <img
                  id="sunImg"
                  src={`Images/${theme === "dark" ? "moon.svg" : "sun.svg"}`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <img src="Images/logo.png" alt="" className="w-16 mx-auto mb-1" />
        <p className="font-semibold text-primary-blue text-xs text-center">
          {t("disclaimer_title")}
        </p>

        <p
          className=" dark:text-white text-[8px] mt-2"
          dangerouslySetInnerHTML={{
            __html: t("disclaimer-content", {
              interpolation: { escapeValue: false },
            }),
          }}
        ></p>

        <p className=" dark:text-white text-xs mt-4">
          {t("disclaimer_footer")}

          <span className="font-bold">
            Las Palmas De Gran Canaria, 11/06/2022
          </span>
        </p>

        <p className=" dark:text-white text-xs mt-4 font-semibold">
          {/* TRADING MILLIMETRICO<sup>®</sup> */}
          Trading Millimetrico Advanced S. A.
        </p>

        <img
          src={`Images/${
            theme === "light" ? "tm-signature.png" : "tm-signature-white.png"
          }`}
          alt=""
          className="w-32 mt-2"
        />

        <button onClick={handleAccept} className="mx-auto">
          <div className="mt-6 btn_gradient hover-animation hover:scale-110 rounded-md inline-flex py-2 px-6 items-center">
            <p className="font-semibold text-sm sm:text-md text-white">
              {t("accept")}
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Modal;
