/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const VideoPaylist = () => {
  const { t } = useTranslation();

  const [play, setPlay] = useState("videos/video5.mp4");

  const [autoplay, setAutoplay] = useState(false);

  useEffect(() => {
    Boolean(autoplay === "videos/video5.mp4");
    setAutoplay(true);
  }, []);

  return (
    <>
      {/* <!-- video playlist --> */}
      <div id="we_reviewed" className="w-padding sm:scroll-m-44 scroll-m-32">
        <div className="max-w">
          <div className="text-center title-spacing">
            <h1 className="heading">
              {t("heading-20")}
              <span className="heading-blue viewpoint">
                {" "}
                <br className="md:hidden" />
                Alfa Advisor<sup>©</sup>
              </span>
            </h1>
          </div>
          <div className="vid-main-wrapper flex justify-center  h-full">
            {/* <!-- THE  PLAYER --> */}
            <div className="md:flex h-full ">
              <div className="bg-black flex justify-center w-full md:w-[650px]">
                <video
                  className="md:h-[50vh] h-[70vh]"
                  controls
                  autoPlay={play === "videos/video5.mp4" ? false : true}
                  id="vid_frame"
                  src={play}
                >
                  {/* <source src={play} type="video/mp4" /> */}
                </video>
              </div>

              {/* <!-- THE PLAYLIST --> */}
              <div className="vid-list-container w-full md:w-[50%]">
                <div className="md:flex justify-between border-t-2 border-b-2 px-3 py-4 border-primary-blue">
                  <h1 className="text-[15px] font-extrabold dark:text-white">
                    Playlist
                  </h1>
                  <h1 className="text-[15px] dark:text-white font-extrabold">
                    15 {t("video")}
                  </h1>
                </div>
                <ol id="vid-list" className="bg-sky-700">
                  {/* <!-- fifth --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video5.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video5.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/one.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video5.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Giulia
                      </div>
                    </a>
                  </li>
                  {/* <!-- seventh --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video7.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video7.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/roger.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video7.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Roger
                      </div>
                    </a>
                  </li>
                  {/* <!-- sixth --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video6.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video6.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/fabio.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video6.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Fabio
                      </div>
                    </a>
                  </li>
                  {/* <!-- fourth --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video4.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video4.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/Michele.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video4.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Michele
                      </div>
                    </a>
                  </li>
                  {/* <!-- eight --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video7.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video8.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/Sofia.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video7.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Sofia
                      </div>
                    </a>
                  </li>

                  {/* Alessia MISSING */}

                  {/* <!-- nine --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video7.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video9.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/Luca.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video7.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Luca
                      </div>
                    </a>
                  </li>
                  {/* <!-- third --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video3.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video3.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/Marina.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video3.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Marina
                      </div>
                    </a>
                  </li>
                  {/* <!-- second --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video2.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video2.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/Claudia.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video2.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Claudia
                      </div>
                    </a>
                  </li>
                  {/* <!-- ten --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video7.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video10.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/Alessia.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video7.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Alessia
                      </div>
                    </a>
                  </li>
                  {/* <!-- first --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video1.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video1.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/jessicia.webp"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video1.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Jessica
                      </div>
                    </a>
                  </li>
                  {/* <!-- 11 --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video11.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video11.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/11.png"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video11.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Matteo
                      </div>
                    </a>
                  </li>
                  {/* <!-- 12 --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video12.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video12.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/12.png"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video12.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Andrea
                      </div>
                    </a>
                  </li>
                  {/* <!-- 13 --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video13.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video13.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/13.png"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video13.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Luca
                      </div>
                    </a>
                  </li>
                  {/* <!-- 14 --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video14.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video14.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/14.png"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video14.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Roberta
                      </div>
                    </a>
                  </li>
                  {/* <!-- 15 --> */}
                  <li>
                    <a
                      className={`cursor-pointer ${
                        play === "videos/video15.mp4" ? "!bg-[#38b6ff]" : ""
                      } `}
                      aria-label=""
                      onClick={() => setPlay("videos/video15.mp4")}
                    >
                      <span className="vid-thumb">
                        <img
                          alt=""
                          width="72"
                          src="videos/thumbnail/15.png"
                        />
                      </span>
                      <div
                        className={`desc ${
                          play === "videos/video15.mp4" ? "!text-[#fff]" : ""
                        }`}
                      >
                        Claudia
                      </div>
                    </a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPaylist;
