import React from "react";
import Chart from "react-apexcharts";
import { ThemeContext } from "../../ThemeContext";

const BarChart = ({ label, data }) => {
  const { theme, setTheme } = React.useContext(ThemeContext);

  const state = {
    options: {
      dataLabels: {
        enabled: false,
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false, // <== line to add
          },
        },
        id: "basic-bar",
      },

      colors: ["#38B6FF"],

      xaxis: {
        categories: label,
        labels: {
          style: {
            colors: theme === "dark" ? "#fff" : "#333",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: theme === "dark" ? "#fff" : "#333",
            backgroundColor: "red",
          },
          formatter: function (val, index) {
            // Function to convert the int decimal to currency format
            const EuroFormat = (value) => {
              const output = new Intl.NumberFormat("it-IT").format(value);

              // add symbol and the last null digit
              return output + ",00";
            };

            // return EuroFormat(val);

            return val
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              .replace(".", ",")
              .replace(",", ".");
          },
        },
      },
    },
    series: [
      {
        name: "",
        data: data,
      },
    ],
  };
  return (
    <div className="">
      <Chart options={state.options} series={state.series} type="bar" />
    </div>
  );
};

export default BarChart;
