import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const History = () => {
  const { t } = useTranslation();

  const myRef = useRef([]);

  useEffect(() => {
    const handleScroll = (e) => {
      histories.forEach((history, index) => {
        if (
          myRef.current[index].getBoundingClientRect().bottom <=
          window.innerHeight / 2 + 200
        ) {
          const values = [...histories];
          values[index]["active"] = true;
          setHistories(values);
        } else {
          const values = [...histories];
          values[index]["active"] = false;
          setHistories(values);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [histories, setHistories] = useState([
    {
      title: "2012",
      description: "y-12",
      image: "right-curve",
      imageClass:
        "w-[10%] hidden md:block left-[40%] h-1/2 min-h-full min-w-[60%] absolute -bottom-[10.5rem]",
      active: false,
    },
    {
      title: "2012-2013",
      description: "y-1213",
      image: "left-curve",
      imageClass:
        "absolute top-44 hidden md:block right-[40%] w-[0%] min-h-full min-w-[60%]",
      active: false,
    },
    {
      title: "2013-2015",
      description: "y-1315",
      image: "right-curve",
      imageClass:
        "w-[10%] hidden md:block left-[40%] h-1/2 min-h-full min-w-[60%] absolute -bottom-[10.5rem]",
      active: false,
    },
    {
      title: "2015",
      description: "y-15",
      image: "left-curve",
      imageClass:
        "absolute top-[12rem] hidden md:block right-[40%] w-[0%] min-h-full min-w-[60%]",
      active: false,
    },
    {
      title: "2016-2017",
      description: "y-1617",
      image: "right-curve",
      imageClass:
        "w-[10%] hidden md:block left-[40%] h-1/2 min-h-full min-w-[60%] absolute -bottom-[11.3rem]",
      active: false,
    },
    {
      title: "2017-2019",
      description: "y-1719",
      image: "left-curve",
      imageClass:
        "absolute top-44 hidden md:block right-[40%] w-[0%] min-h-full min-w-[60%]",
      active: false,
    },
    {
      title: "2019",
      description: "y-19",
      image: "right-curve",
      imageClass:
        "w-[10%] hidden md:block left-[40%] h-1/2 min-h-full min-w-[60%] absolute -bottom-[10.5rem]",
      active: false,
    },
    {
      title: "2021",
      description: "y-21",
      image: "left-curve",
      imageClass:
        "absolute top-[13rem] hidden md:block right-[40%] w-[0%] min-h-full min-w-[60%]",
      active: false,
    },
    {
      title: "2022",
      description: "y-22",
      image: "right-curve",
      imageClass: 
        "w-[10%] hidden md:block left-[40%] h-1/2 min-h-full min-w-[60%] absolute -bottom-[10.5rem]",
      active: false,
    },
    {
      title: "2023",
      description: "y-23",
      image: "",
      imageClass: "",
      active: false,
    },
  ]);

  return (
    <>
      {/* <!-- history --> */}
      <div className="w-padding ">
        <div className="sm:flex text-center justify-center gap-2 title-spacing">
          <h1 className="heading"> {t("heading-19")}</h1>
          <div className="flex justify-center sm:justify-start">
            <p className="heading-blue viewpoint">Trading Millimetrico®</p>
          </div>
        </div>
        <div className="max-w sm:w-[70%]">
          {histories.map((history, index) => {
            if (index % 2 === 0) {
              return (
                <div className="grid sm:grid-cols-2 mt-4" key={index}>
                  <div
                    className={`${
                      history.active ? "bg-primary-blue" : "bg-[#FAFAFA]"
                    } shadow-lg relative flex items-center rounded-r-xl text-black`}
                    ref={(element) => {
                      myRef.current[index] = element;
                    }}
                    // ref={myRef}
                  >
                    <div
                      className={`h-full w-1 ${
                        history.active
                          ? "bg-primary-dark_body/70"
                          : "bg-primary-blue"
                      }`}
                    ></div>
                    <div
                      className={`p-10 flex flex-col items-center w-full md:items-start text-center md:text-left ${
                        history.active && "text-white"
                      }`}
                    >
                      <h1 className="md:text-[44px] text-[30px] font-bold">
                        {history.title}
                      </h1>
                      <p
                        className="mt-9 font-semibold text-[15px] "
                        dangerouslySetInnerHTML={{
                          __html: t(history.description, {
                            interpolation: { escapeValue: false },
                          }),
                        }}
                      ></p>
                    </div>
                    <img
                      src={`Images/${history.image}.svg`}
                      className={history.imageClass}
                      alt=""
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div className="grid sm:grid-cols-2 mt-4" key={index}>
                  <div
                    className={`${
                      history.active ? "bg-primary-blue" : "bg-[#FAFAFA]"
                    } rounded-r-xl shadow-lg relative order-2 flex items-center text-black`}
                    ref={(element) => {
                      myRef.current[index] = element;
                    }}
                  >
                    <div
                      className={`h-full w-1 ${
                        history.active
                          ? "bg-primary-dark_body/70"
                          : "bg-primary-blue"
                      }`}
                    ></div>
                    <div
                      className={`p-10 flex flex-col items-center md:items-start text-center md:text-left w-full ${
                        history.active && "text-white"
                      }`}
                    >
                      <h1 className="md:text-[44px] text-[30px] font-bold ">
                        {history.title}
                      </h1>
                      <p
                        className="mt-9 font-semibold text-[15px]"
                        dangerouslySetInnerHTML={{
                          __html: t(history.description, {
                            interpolation: { escapeValue: false },
                          }),
                        }}
                      ></p>
                      <div className="">
                        <img
                          src={`Images/${history.image}.svg`}
                          className={history.imageClass}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className=""></div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default History;
