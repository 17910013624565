import React from "react";
import { useTranslation } from "react-i18next";

const MyFixBook = () => {
  const Images = ["fx1.jpeg", "fx2.jpeg", "fx3.jpeg","fx4.jpeg"];
  const { t } = useTranslation();
  return (
    <div className="w-padding sm:scroll-m-44 scroll-m-32 max-w" id="myfixbook">
      <div className="sm:flex text-center justify-center gap-2 title-spacing">
        <h1 className="heading"> {t("heading-14")}</h1>
        <div className="flex justify-center sm:justify-start">
          <p className="heading-blue viewpoint"> {t("certi")}</p>
        </div>
      </div>

      {/* <div className="gap-4 flex flex-col">
        {Images.map((item, index) => (
          <img
            src={`Images/myfixbook/${item}`}
            alt=""
            className="rounded-lg"
            key={index}
          />
        ))}
      </div> */}

      <div class="gap-4 flex flex-col items-center">
        {/* <img src="Images/myfixbook/conservativo.jpeg" alt="" class="rounded-lg" />
        <a 
          href="https://www.myfxbook.com/members/TM2021/alfa-advisor-conservativo/10013380" 
          class="mt-1 mb-10 text-gray-900 dark:text-white  text-center text-xl font-bold hover:underline "
          target="_blank">
            
            {t("heading-fb-1")}
        </a> */}
        <img src="Images/myfixbook/nov23.jpeg" alt="" class="rounded-lg" />
        <a 




          href="https://www.myfxbook.com/members/TM2021/alfa-advisor/10527437" 
          class="cursor mt-3 mb-4 text-primary-blue dark:text-white text-center text-xl font-bold hover:bg-primary-blue hover:text-white border-primary-blue w-fit px-5 py-2 rounded-md border-2 "
          target="_blank">
            {t("heading-fb-2")}
        </a>
      </div>
      {/* <p 
        className="text-xs font-semibold dark:text-white text-center mt-6 md:mt-10"
        dangerouslySetInnerHTML={{
          __html: t("heading-fb-3", {
            interpolation: { escapeValue: false },
          }),
        }}>
      </p> */}
      <p className="text-xs font-semibold dark:text-white text-center mt-2 md:mt-4">
        *{t("heading-15")}
      </p>
    </div>
  );
};

export default MyFixBook;
