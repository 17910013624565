import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const QuestionFormat = ({ ques, ans }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState();
  return (
    <>
      <div className="w-full px-5 py-2 text-base">
        <button
          className="dark:text-white w-full font-bold hover-animation py-3 text-left flex items-center justify-between dark:hover:text-primary-blue hover:text-[#37b5ff]"
          onClick={() => setShow(!show)}
        >
          <h1
            dangerouslySetInnerHTML={{
              __html: t(ques, {
                interpolation: { escapeValue: false },
              }),
            }}
          ></h1>
          {show ? (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="currentColor"
                  d="M104.704 685.248a64 64 0 0 0 90.496 0l316.8-316.8l316.8 316.8a64 64 0 0 0 90.496-90.496L557.248 232.704a64 64 0 0 0-90.496 0L104.704 594.752a64 64 0 0 0 0 90.496z"
                />
              </svg>
            </div>
          ) : (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="currentColor"
                  d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8l316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"
                />
              </svg>
            </div>
          )}
        </button>

        {show ? (
          <div className="font-semibold dark:text-white text-[15px]">
            <p
              dangerouslySetInnerHTML={{
                __html: t(ans, {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default QuestionFormat;
