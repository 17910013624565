import { useState } from "react";
import { useTranslation } from "react-i18next";

const ZoomModal = ({ setIsZoomModalOpen, isZoomModalOpen }) => {
  const { t } = useTranslation();

  const initialValues = {
    fullname: "",
    phone: "",
    telegram: "",
  };

  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const apiUrl = "https://tradingmillimetrico.com/customer.php";
    const apiKey = "dG1zaXRlOjQybmVkZXY=";

    const postData = {
      fullname: values.fullname,
      phone: values.phone,
      telegram: values.telegram,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apikey: apiKey,
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        console.log("API call successful");
        setIsZoomModalOpen(false);
        window.location.href = "https://us06web.zoom.us/j/8336687632";
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <div
      class={`bg-gray-700/50 w-full h-[100vh] backdrop-blur-xl backdrop-filter fixed inset-0 z-[1000]  place-items-center  ${
        isZoomModalOpen ? "grid" : "hidden"
      }`}
    >
      <div className="bg-white dark:bg-primary-dark_body rounded-xl w-full max-w-[90%] md:max-w-lg md:p-10 max-h-[90vh] overflow-y-auto">
        <div className="p-10 md:p-0">
          <div className="bg-primary-blue/30 rounded-lg p-[15px] lg:p-[37px] order-[-1] md:order-2">
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <input
                required
                type="text"
                value={values.fullname}
                placeholder={t("name")}
                name="fullname"
                className="w-full bg-white py-2 px-3 rounded-lg"
                onChange={handleChange}
              />

              <input
                required
                type="number"
                placeholder={t("phone")}
                value={values.phone}
                name="phone"
                className="w-full bg-white py-2 px-3 rounded-lg"
                onChange={handleChange}
              />
              <input
                type="number"
                placeholder={t("telegram-phone")}
                value={values.telegram}
                name="telegram"
                className="w-full bg-white py-2 px-3 rounded-lg"
                onChange={handleChange}
              />

              <button aria-label="submit" type="submit" className="mx-auto">
                <div className="md:mt-6 btn_gradient hover-animation hover:scale-110 rounded-md inline-flex py-2 px-6 items-center">
                  <p className="font-semibold text-sm sm:text-md text-white">
                    {t("submit")}
                  </p>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZoomModal;
