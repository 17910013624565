import { useTranslation } from "react-i18next";

const Zoom = ({ setIsZoomModalOpen }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className="w-padding text-center sm:scroll-m-44 scroll-m-32"
        id="zoom"
      >
        <div className="max-w ">
          <h1 className="heading title-spacing ">
            {/* {t("piano-compensi-title-1")} */}
            <span className="heading-blue inline-block viewpoint">
              {t("zoom-settimanale")}
            </span>
          </h1>

          {/* first section */}
          <div className="grid grid-cols-1 justify-items-center md:grid-cols-2 items-center gap-6 md:gap-16">
            {/* Left */}
            <div className="w-full text-center md:text-left">
              {/* <Subtitle firstText={t("commissioni")} /> */}
              {/* <Subtitle firstText='Zoom Subtitle' /> */}
              <div className="font-semibold dark:text-white lg:text-[20px] text-[15px] text-justify space-y-4">
                <p
                  className=" d"
                  dangerouslySetInnerHTML={{
                    __html: t("zoom-desc", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></p>

                <div className="space-y-1/2 "></div>

                <p>
                  {/* <p className="">{t("commissioni-p2")}</p> */}
                  <br />
                  {/* <p className="">{t("commissioni-p3")}</p> */}
                </p>
              </div>
            </div>

            {/* Right */}
            <div>
              <picture className="">
                <img src="Images/zoom.png" alt="" className="max-h-[30rem]" />
              </picture>
            </div>
          </div>

          {/* <!-- button --> */}
          <a
            aria-label=""
            // href="https://us06web.zoom.us/j/8336687632"
            className="mt-10 block md:mt-0 cursor-pointer"
            onClick={() => setIsZoomModalOpen(true)}
          >
            <div className="main-btn">
              <p className="font-extrabold text-xs md:text-lg text-white text-left uppercase">
                {t("zoom-btn")}
              </p>
              <svg
                className="h-5 w-5"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 7.7257C0 7.346 0.282154 7.03221 0.648229 6.98255L0.75 6.9757L15.75 6.9757C16.1642 6.9757 16.5 7.31149 16.5 7.7257C16.5 8.1054 16.2178 8.41919 15.8518 8.46885L15.75 8.4757L0.75 8.4757C0.335786 8.4757 0 8.13991 0 7.7257Z"
                  fill="#fff"
                />
                <path
                  d="M9.17101 2.23277C8.87749 1.94051 8.87646 1.46564 9.16872 1.17211C9.43442 0.905273 9.85103 0.880171 10.1451 1.09739L10.2294 1.16983L16.2794 7.19383C16.547 7.46032 16.5714 7.87843 16.3524 8.1725L16.2794 8.25673L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.596 9.09817 13.3028L9.17096 13.2189L14.687 7.725L9.17101 2.23277Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Zoom;
