import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <footer className="w-padding">
        <div className="max-w">
          <div className="md:flex justify-between items-center md:gap-5">
            <picture>
              <img
                src="Images/logo.png"
                className="md:h-[144px] h-[130px] w-[130px] md:w-[144px] shrink-0 mb-6 md:mb-0 mx-auto md:mx-0"
                alt=""
              />
            </picture>

            <div className="flex flex-row md:justify-end md:items-end ">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 gap-x-10">
                {/* disclaimer */}
                <a
                  aria-label="arrow-back"
                  href={t("disclaimer-link")}
                  target="_blank"
                >
                  <div className="flex items-center gap-3 dark:text-white hover:scale-105 hover-animation">
                    <svg
                      className="h-3 w-3"
                      viewBox="0 0 7 12"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.25961 0.25961C0.605756 -0.0865366 1.16697 -0.0865366 1.51312 0.25961L6.24039 4.98688C6.58654 5.33303 6.58654 5.89424 6.24039 6.24039L1.51312 10.9677C1.16697 11.3138 0.605756 11.3138 0.25961 10.9677C-0.0865366 10.6215 -0.0865366 10.0603 0.25961 9.71416L4.36013 5.61364L0.25961 1.51312C-0.0865366 1.16697 -0.0865366 0.605756 0.25961 0.25961Z" />
                    </svg>
                    <p className="font-bold text-[18px]">{t("disclaimer")}</p>
                  </div>
                </a>
                {/* 2) Garanzia */}
                <a aria-label="" href={t("garanzia-link")} target="_blank">
                  <div className="flex items-center dark:text-white gap-3  hover:scale-105 hover-animation">
                    <svg
                      className="h-3 w-3"
                      viewBox="0 0 7 12"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.25961 0.25961C0.605756 -0.0865366 1.16697 -0.0865366 1.51312 0.25961L6.24039 4.98688C6.58654 5.33303 6.58654 5.89424 6.24039 6.24039L1.51312 10.9677C1.16697 11.3138 0.605756 11.3138 0.25961 10.9677C-0.0865366 10.6215 -0.0865366 10.0603 0.25961 9.71416L4.36013 5.61364L0.25961 1.51312C-0.0865366 1.16697 -0.0865366 0.605756 0.25961 0.25961Z" />
                    </svg>

                    <p className="font-bold dark:text-white text-[18px]">
                      {t("garanzia")}
                    </p>
                  </div>
                </a>
                {/* 3) Contratto fornitura di servizi */}
                <a
                  aria-label=""
                  href={t("service-supply-link")}
                  target="_blank"
                >
                  <div className="flex items-center dark:text-white gap-3 hover:scale-105 hover-animation">
                    <svg
                      className="h-3 w-3"
                      viewBox="0 0 7 12"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.25961 0.25961C0.605756 -0.0865366 1.16697 -0.0865366 1.51312 0.25961L6.24039 4.98688C6.58654 5.33303 6.58654 5.89424 6.24039 6.24039L1.51312 10.9677C1.16697 11.3138 0.605756 11.3138 0.25961 10.9677C-0.0865366 10.6215 -0.0865366 10.0603 0.25961 9.71416L4.36013 5.61364L0.25961 1.51312C-0.0865366 1.16697 -0.0865366 0.605756 0.25961 0.25961Z" />
                    </svg>

                    <p className="font-bold dark:text-white text-[18px]">
                      {t("service-supply-agreement")}
                    </p>
                  </div>
                </a>
                {/* 4) Contratto networking */}
                <a aria-label="" href={t("contract-link")} target="_blank">
                  <div className="flex dark:text-white items-center gap-3 hover:scale-105 hover-animation">
                    <svg
                      className="h-3 w-3"
                      viewBox="0 0 7 12"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.25961 0.25961C0.605756 -0.0865366 1.16697 -0.0865366 1.51312 0.25961L6.24039 4.98688C6.58654 5.33303 6.58654 5.89424 6.24039 6.24039L1.51312 10.9677C1.16697 11.3138 0.605756 11.3138 0.25961 10.9677C-0.0865366 10.6215 -0.0865366 10.0603 0.25961 9.71416L4.36013 5.61364L0.25961 1.51312C-0.0865366 1.16697 -0.0865366 0.605756 0.25961 0.25961Z" />
                    </svg>
                    <p className="font-bold dark:text-white text-[18px]">
                      {t("contratto_networking")}
                    </p>
                  </div>
                </a>
                {/* 5) Termini e condizioni  */}
                <a
                  aria-label=""
                  href={t("term-condition-link")}
                  target="_blank"
                >
                  <div className="flex dark:text-white items-center gap-3 hover:scale-105 hover-animation">
                    <svg
                      className="h-3 w-3"
                      viewBox="0 0 7 12"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.25961 0.25961C0.605756 -0.0865366 1.16697 -0.0865366 1.51312 0.25961L6.24039 4.98688C6.58654 5.33303 6.58654 5.89424 6.24039 6.24039L1.51312 10.9677C1.16697 11.3138 0.605756 11.3138 0.25961 10.9677C-0.0865366 10.6215 -0.0865366 10.0603 0.25961 9.71416L4.36013 5.61364L0.25961 1.51312C-0.0865366 1.16697 -0.0865366 0.605756 0.25961 0.25961Z" />
                    </svg>
                    <p className="font-bold dark:text-white text-[18px]">
                      {t("terms-conditions")}
                    </p>
                  </div>
                </a>
                {/* 6) Privacy policy */}
                <a
                  aria-label=""
                  href={t("privacy-policy-link")}
                  target="_blank"
                >
                  <div className="flex dark:text-white items-center gap-3 hover:scale-105 hover-animation">
                    <svg
                      className="h-3 w-3"
                      viewBox="0 0 7 12"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.25961 0.25961C0.605756 -0.0865366 1.16697 -0.0865366 1.51312 0.25961L6.24039 4.98688C6.58654 5.33303 6.58654 5.89424 6.24039 6.24039L1.51312 10.9677C1.16697 11.3138 0.605756 11.3138 0.25961 10.9677C-0.0865366 10.6215 -0.0865366 10.0603 0.25961 9.71416L4.36013 5.61364L0.25961 1.51312C-0.0865366 1.16697 -0.0865366 0.605756 0.25961 0.25961Z" />
                    </svg>
                    <p className="font-bold dark:text-white text-[18px]">
                      {t("privacy-policy")}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="bg-primary-blue p-4 px-6 mt-7 rounded-md gap-1 md:gap-4 flex flex-col justify-center items-center">
            <div className="flex items-center gap-4 flex-shrink-0">
              <a
                target="_blank"
                aria-label="telegram"
                href="https://t.me/TradingMillimetrico"
              >
                <img src="Images/telegram.svg" alt="" />
              </a>
              <a
                target="_blank"
                aria-label="messenger"
                href="https://m.me/tradingmillimetrico"
              >
                <img src="Images/messenger.svg" alt="" />
              </a>
              <a
                target="_blank"
                aria-label="youtube"
                href="https://www.youtube.com/channel/UCKBpboeL03_V9XrYZr2FTVw"
              >
                <img src="Images/youtube.svg" alt="" />
              </a>
              <a
                target="_blank"
                aria-label="facebook"
                href="https://www.facebook.com/TradingMillimetrico"
              >
                <img src="Images/facebook.svg" alt="" />
              </a>
              <a
                target="_blank"
                aria-label="instagram"
                href="https://www.instagram.com/tradingmillimetrico/"
              >
                <img src="Images/instagram.svg" alt="" />
              </a>
              <a
                target="_blank"
                aria-label="message"
                href="mailto:info@tradingmillimetrico.com"
              >
                <img src="Images/message.svg" alt="" />
              </a>
              <a
                target="_blank"
                aria-label="whatsapp"
                href="https://wa.me/393515452462"
              >
                <img src="Images/whatsapp.svg" alt="" />
              </a>
            </div>

            

            <h1 
              className="text-white font-[14px] mt-3 md:mt-0 text-center"
            >
              <span 
                dangerouslySetInnerHTML={{
                  __html: t("footer-paragraph", {
                    interpolation: { escapeValue: false },
                  }),
                }}>
              </span>&nbsp;-&nbsp;
              <span 
                dangerouslySetInnerHTML={{
                  __html: t("all-right-reserved", {
                    interpolation: { escapeValue: false },
                  }),
                }}>
                </span>
            </h1>

          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
