import React from "react";
import ReactDOM from "react-dom/client";
import "./CSS/Tailwind.css";
import App from "./App";
import "./CSS/Main.css";
import reportWebVitals from "./reportWebVitals";
import "./main";
import "./fonts/Mont-Bold.ttf";
import "./fonts/Mont-Heavy.ttf";
import "./fonts/Mont-Regular.ttf";
import "./fonts/Mont-SemiBold.ttf";
import { ThemeProvider } from "./ThemeContext";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { BrowserRouter } from "react-router-dom";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // resources,
    fallbackLng: "it",
    supportedLngs: ["it", "en", "es"],
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    react: { useSuspense: false },
    initImmediate: false,
  });

export default i18n;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
);

reportWebVitals();
