import React from "react";
import { useTranslation } from "react-i18next";

const Newspaper = () => {
  const { t } = useTranslation();

  const companies = [
    {
      title: "lastampa",
      path: "la-stampa.png",
      link: "https://www.lastampa.it/native/economia/2023/03/29/news/lascesa_dellautomazione_come_cambia_il_trading_online-12723012/"
    },
    {
      title: "repubblica",
      path: "repubblica.png",
      link: "https://www.repubblica.it/native/economia/2023/02/28/news/il_trading_online_spopola_ma_non_tutti_gli_strumenti_sono_sicuri-389772458"
    },
    {
      title: "forbes",
      path: "forbes.png",
      link: "https://forbes.it/2022/09/01/trading-online-lanciato-mercato-bot-intelligente-automatico/"
    },
    {
      title: "libero",
      path: "libero.png",
      link: "https://www.liberoquotidiano.it/news/general/32122468/forex-azioni-criptovalute-il-trend-oggi-e-il-trading-online.html"
    },
    {
      title: "marketing",
      path: "marketing.png",
      link: "https://www.marketingefinanza.com/alfa-advisor-software-trading-istituzionale-2393.html"
    },
    {
      title: "tgcom",
      path: "tgcom.png",
      link: "https://movida.tgcom24.it/2022/05/24/la-rivoluzione-del-trading-on-line/"
    },
    {
      title: "notizie",
      path: "notizie.png",
      link: "https://www.notizienazionali.it/notizie/economia/34536/come-investire-in-sicurezza-anche-i-piccoli-capitali"
    },
    {
      title: "arssaggro",
      path: "arssaggro.png",
      link: "https://www.ilmessaggero.it/speciali/pm/oggi_il_trading_si_fa_in_automatico_con_gli_expert_advisor-6473503.html?fbclid=IwAR2C1Kl9nblTxyovdvBazl_3HbA4slesp4zH1YIfga42wzeDByxdJ06EZtc"
    },
    {
      title: "leggo",
      path: "leggo.png",
      link: "https://www.leggo.it/speciali/pm/cos_e_l_interesse_composto_trading_automatico_con_gli_expert_advisor-6641765.html"
    },
    {
      title: "leggi",
      path: "leggi.png",
      link: "https://www.leggioggi.it/2022/02/16/trading-millimetrico-la-rivoluzione-del-trading-online/"
    },
    {
      title: "ilga",
      path: "ilga.png",
      link: "https://www.ilgazzettino.it/speciali/pm/trading_millimetrico_e_il_suo_expert_advisor_alla_scoperta_di_alfa_advisor-6797082.html"
    },
    {
      title: "aggari",
      path: "aggari.png",
      link: "https://www.affaritaliani.it/il-trend-oggi-il-trading-online-810388.html"
    },
    {
      title: "first",
      path: "first.png",
      link: "https://www.firstonline.info/trading-online-come-funziona-il-trading-automatico-il-case-history-di-alfa-advisor/"
    }
  ];
  return (
    <>
      <div className="">
        <div className="bg-gradient-to-b from-event_gradient-light_from to-white dark:from-event_gradient-dark_from dark:via-event_gradient-dark_via dark:to-event_gradient-dark_to pb-16 flex justify-center text-center">
          <div className="">
            <div className="title-spacing">
              <h1 className="heading mt-14"> {t("heading-21")}</h1>
              <h1 className="heading-blue viewpoint inline-block">
                Trading Millimetrico®
              </h1>
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-2 justify-items-center xl:grid-cols-4 items-center gap-10">
              {companies.map((company, index) => (
                <div
                  className="bg-white w-full grid place-items-center rounded-2xl h-[120px] p-2"
                  key={index}
                >
                  <a
                    href={`${company.link}`}
                    target="_blank"
                    className="w-full grid place-items-center h-full"
                  >
                    <img
                      src={`Images/${company.path}`}
                      alt=""
                      className="w-[80%] h-full object-contain"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newspaper;
