import { useState } from "react";
import { useTranslation } from "react-i18next";
import { send } from "emailjs-com";
import { Alert, Snackbar } from "@mui/material";
import { SERVICE_ID, TEMPLATE_ID, USER_ID } from "../../emailConfig";
import Map from "./Map";

const ContactUs = () => {
  const { t } = useTranslation();
  const [state, setState] = useState(false);
  const [message, setMessage] = useState("");
  const [sucess, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
  };
  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setState(false);
  };
  const sendMail = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      to_name: values.fname,
      from_name: values.fname,
      message: `${values.fname} ${values.lname} , ${values.email} , ${values.phone} ${values.message}`,
    };
    console.log(data);
    send(SERVICE_ID, TEMPLATE_ID, data, USER_ID)
      .then((response) => {
        setMessage("Il messaggio è stato inviato con successo!");
        setLoading(false);
        setState(true);
        setSucess(true);
      })
      .catch((err) => {
        setLoading(false);
        setMessage("Qualcosa è andato storto! Per favore riprova");
        setSucess(false);
        setState(true);
      });

    setValues(initialValues);
  };

  return (
    <>
      {/* <!-- contact us --> */}
      <div id="contacts" className="w-padding sm:scroll-m-44 scroll-m-32">
        <Snackbar open={state} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={sucess ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
        <div className="max-w">
          <div className="">
            <h1 className="heading text-center md:text-center title-spacing">
              {t("c-text-02")}
              <span className="heading-blue viewpoint inline-block text-center mb-4 ml-4">
                {t("c-text-03")}
              </span>
            </h1>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-[6fr_4fr] gap-10 items-center overflow-hidden">
            {/* <!-- left --> */}
            <Map />

            {/* <!-- right --> */}
            <div className="bg-primary-blue rounded-lg p-[15px] lg:p-[37px] order-[-1] md:order-2">
              {/* <h1 className="lg:text-[56px] text-white text-[30px] font-bold">
                {t("c-text-01")}
              </h1> */}
              <form onSubmit={sendMail} className="space-y-4">
                <input
                  required
                  type="text"
                  value={values.fname}
                  placeholder={t("name")}
                  name="fname"
                  onChange={handleChange}
                  id=""
                  className="w-full bg-white py-2 px-3 rounded-lg"
                />
                <input
                  value={values.lname}
                  required
                  type="text"
                  placeholder={t("lastname")}
                  name="lname"
                  onChange={handleChange}
                  id=""
                  className="w-full bg-white py-2 px-3 rounded-lg"
                />
                <input
                  required
                  type="email"
                  placeholder={t("email")}
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  id=""
                  className="w-full bg-white py-2 px-3 rounded-lg"
                />
                <input
                  required
                  type="number"
                  placeholder={t("phone")}
                  name="phone"
                  onChange={handleChange}
                  value={values.phone}
                  id=""
                  className="w-full bg-white py-2 px-3 rounded-lg"
                />
                <textarea
                  required
                  name="message"
                  id=""
                  cols=""
                  value={values.message}
                  onChange={handleChange}
                  rows="8"
                  placeholder={t("message")}
                  className="w-full bg-white py-2 px-3 rounded-lg"
                ></textarea>
                <button
                  aria-label="submit"
                  type="submit"
                  className="bg-white hover:scale-110 hover-animation text-primary-blue py-3 px-12 mt-6 rounded-lg"
                >
                  {!loading ? t("send") : "Sending..."}
                </button>
                <p className="text-white mt-6 font-normal text-[15px]">
                  {t("terms1")}{" "}
                  <a
                    href="https://panel.tradingmillimetrico.com/docs/Termini%20e%20Condizioni%20Generali.pdf"
                    className="no-underline"
                    target="_blank"
                  >
                    "
                    <span className="no-underline hover:underline">
                      {t("terms1-link")}
                    </span>
                    "{" "}
                  </a>{" "}
                  {t("terms2")}{" "}
                  <a
                    href="https://panel.tradingmillimetrico.com/docs/Politica%20Privacy%20&%20Politica%20Cookie.pdf"
                    className="no-underline"
                    target="_blank"
                  >
                    "
                    <span className="no-underline hover:underline">
                      Privacy & Cookie Policy
                    </span>
                    "
                  </a>
                </p>
              </form>
            </div>
          </div>
          <div className="h-1 w-full bg-primary-blue mt-16"></div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
