import { useEffect, useState } from "react";
import Modal from "./Components/Common/Modal";
import Footer from "./Components/Layouts/Footer";
import Header from "./Components/Layouts/Header";
import Navbar from "./Components/Layouts/Navbar";
import Choose from "./Components/Sections/Choose";
import Company from "./Components/Sections/Company";
import CompoundIntrest from "./Components/Sections/CompoundIntrest";
import ContactUs from "./Components/Sections/ContactUs";
import ExpertAdvisor from "./Components/Sections/ExpertAdvisor";
import Faq from "./Components/Sections/Faq";
import Founder from "./Components/Sections/Founder";
import Hero from "./Components/Sections/Hero";
import History from "./Components/Sections/History";
import ImageSlider from "./Components/Sections/ImageSlider";
import MyFixBook from "./Components/Sections/MyFixBook";
import Newspaper from "./Components/Sections/Newspaper";
import Zoom from "./Components/Sections/Zoom";
import PianoCompensi from "./Components/Sections/PianoCompensi";
// import PricePlans from "./Components/Sections/PricePlans_promo_15_May";
import PricePlans from "./Components/Sections/PricePlans3";
import Prop from "./Components/Sections/Prop2";
import Vps from "./Components/Sections/Vps";
import TradingInstitutional from "./Components/Sections/TradingInstitutional";
import VideoPaylist from "./Components/Sections/VideoPaylist";
import ReactPixel from "react-facebook-pixel";
import ZoomModal from "./Components/Common/ZoomModal";
import LinkButton from "./Components/Common/LinkButton";

const advancedMatching = { em: "some@email.com" };
const options = {
  autoConfig: true,
  debug: false,
};

const App = () => {
  useEffect(() => {
    ReactPixel.init("3445145759075529", advancedMatching, options);
    ReactPixel.pageView();
  }, []);

  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  return (
    <>
      <div className="dark:bg-primary-dark_body">
        {/* <SecondModal /> */}
        <ZoomModal
          isZoomModalOpen={isZoomModalOpen}
          setIsZoomModalOpen={setIsZoomModalOpen}
        />

        <Modal />
        <Header />
        <Navbar />
        <div className="sections">
          <Hero />
          <LinkButton />
          <TradingInstitutional />
          <Company />
          <ExpertAdvisor />
          <CompoundIntrest />
          <Choose />
          {/* <Certified /> */}
          <MyFixBook />
          <PricePlans />
          {/* <Prop /> */}
          <Vps />
          {/* <VideoAnalisi /> */}
          {/* <Analisi /> */}
          <Zoom setIsZoomModalOpen={setIsZoomModalOpen} />
          <PianoCompensi />
          {/* <Agent /> */}
          <Founder />
          <History />
          {/* <Events /> */}
          <VideoPaylist />
          <ImageSlider />
          <Newspaper />
          <Faq />
          {/* <Map /> */}
          <ContactUs />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default App;
