const MarqueVal = [
  {
    id: 1,
    title: "EUR/CNY",
    Value: 7.2722,
    MarqueCss: "text-[#FF0000] font-bold",
    arrow: "▼",
  },
  {
    id: 2,
    title: "EUR/USD",
    Value: 1.0759,
    MarqueCss: "text-[#008001] text-xs md:text-[14px] font-bold",
    arrow: "▲",
  },
  {
    id: 3,
    title: "GBP/USD",
    Value: 1.0759,
    MarqueCss: "text-[#008001] text-xs md:text-[14px] font-bold",
    arrow: "▲",
  },
  {
    id: 4,
    title: "USD/JPY",
    Value: 131.68,
    MarqueCss: "text-[#FF0000] font-bold",
    arrow: "▼",
  },
  {
    id: 5,
    title: "EUR/JPY",
    Value: 141.69,
    MarqueCss: "text-[#FF0000] font-bold",
    arrow: "▼",
  },
  {
    id: 6,
    title: "USD/CHF",
    Value: 0.9322,
    MarqueCss: "text-[#008001] text-xs md:text-[14px] font-bold",
    arrow: "▲",
  },
  {
    id: 7,
    title: "USD/CAD",
    Value: 1.3430,
    MarqueCss: "text-[#008001] text-xs md:text-[14px] font-bold",
    arrow: "▲",
  },
  {
    id: 8,
    title: "AUD/USD",
    Value: 0.6906,
    MarqueCss: "text-[#008001] text-xs md:text-[14px] font-bold",
    arrow: "▲",
  },
  {
    id: 9,
    title: "EUR/GBP",
    Value: 0.8857,
    MarqueCss: "text-[#008001] text-xs md:text-[14px] font-bold",
    arrow: "▲",
  },
  {
    id: 10,
    title: "EUR/CHF",
    Value: 1.0029,
    MarqueCss: "text-[#008001] text-xs md:text-[14px] font-bold",
    arrow: "▲",
  }
];

export default MarqueVal;
