import React, { useState } from "react";
import { ThemeContext } from "../../ThemeContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { makeStyles } from "@material-ui/styles";

const Navbar = () => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar((preval) => !preval);
  };

  const countries = [
    {
      label: "Italy",
      src: "Images/flags/it.png",
      link: " ",
      value: "IT",
    },
    {
      label: "Spain",
      src: "Images/flags/es.png",
      link: " ",
      value: "ES",
    },
    {
      label: "English",
      src: "Images/flags/en.png",
      link: " ",
      value: "EN",
    },
  ];

  const selectedFlag = i18n.language;

  const [country, setCountry] = React.useState(
    `Images/flags/${selectedFlag}.png`
  );

  const handleChange = (event) => {
    const selected = event.target.value.slice(13, 15);
    setCountry(event.target.value);
    i18next.changeLanguage(selected);
  };

  const langStyle = {
    menuProps: {
      sx: {
        // "&& .Mui-selected": {
        //   backgroundColor: "#38b6ff",
        // },
        "&& .MuiList-root": {
          backgroundColor: "#04153f",
        },
      },
    },
    sx: {
      color: "white",
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
      },
      ".MuiSvgIcon-root ": {
        fill: "white !important",
      },
    },
    menuItem: { backgroundColor: "#04153f" },
  };

  const dropdownStyle = {
    menuProps: {
      sx: {
        // "&& .Mui-selected": {
        //   backgroundColor: "#38b6ff",
        // },
        "&& .MuiList-root": {
          backgroundColor: theme == "dark" ? "#04153f" : "white",
        },
      },
    },
    sx: {
      fontWeight: "bold",
      color: theme == "dark" ? "white" : "black",
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      ".MuiSvgIcon-root ": {
        fill: theme == "dark" ? "white !important" : "black !important",
      },
    },
    menuItem: {
      color: theme == "dark" ? "white" : "black",
    },
  };

  return (
    <>
      <div className="w-padding bg-opacity-50 hover-animation backdrop-blur-[15px] backdrop-filter sticky top-0 z-30">
        <div id="navbar" className="hover-animation">
          <div className="flex justify-end mt-4">
            {/* <select
              onChange={(e) => i18next.changeLanguage(e.target.value)}
              id="countries"
              className="bg-gray-50 border border-primary-blue text-gray-900 text-sm rounded-lg focus:ring-primary-blue focus:border-primary-blue block w-44 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-blue dark:focus:border-primary-blue"
            >
              <option defaultValue="en">Select Language</option>
              <option value="en">English</option>
              <option value="it">Italian</option>
            </select> */}
          </div>
          <div className="max-w flex items-center justify-between lg:justify-between xl:gap-5 gap-10 mt-[2px]">
            <div className="flex items-center gap-3">
              {/* logo */}
              <div className="">
                <a aria-label="index" href="./index.html">
                  <picture>
                    <img
                      src="Images/logo.png"
                      className="sm:h-32 h-20 w-20 sm:w-32"
                      alt="logo"
                    />
                  </picture>
                </a>
              </div>
              {/* nav-links */}
              <div>
                <ul className="lg:flex hidden gap-7 relative z-10 items-center lg:ml-10">
                  <li className="">
                    <a
                      aria-label="home"
                      href="#home"
                      // className="nav-items nav-active"
                      className="nav-items"
                    >
                      {t("menu.home")}
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="chi siamo"
                      className="nav-items"
                      href="#who_we_are"
                    >
                      {t("menu.who_we_are")}
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="computer"
                      className="nav-items"
                      href="#myfixbook"
                    >
                      {t("menu.myfxbook")}
                    </a>
                  </li>{" "}
                  <li>
                    <a
                      aria-label="computer"
                      className="nav-items"
                      href="#purchase"
                    >
                      {t("menu.prices")}
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="computer"
                      className="nav-items"
                      href="#piano"
                    >
                      {t("menu.work_with_us")}
                    </a>
                  </li>
                  {/* <li>
                    <a
                      aria-label="computer"
                      className="nav-items"
                      href="#computer"
                    >
                      {t("calculator")}
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      aria-label="lavora"
                      className="nav-items"
                      href="#work_with_us"
                    >
                      {t("work_with_us")}
                    </a>
                  </li> */}
                  <li>
                    <a
                      aria-label="Recensioni"
                      className="nav-items"
                      href="#we_reviewed"
                    >
                      {t("menu.reviews")}
                    </a>
                  </li>
                  <li>
                    <a aria-label="faq" className="nav-items" href="#faq">
                      {t("menu.faq")}
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="contacts"
                      className="nav-items"
                      href="#contacts"
                    >
                      {t("menu.contact_us")}
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="area-riservata"
                      className="nav-items"
                      href="https://panel.tradingmillimetrico.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("menu.restricted_area")}
                    </a>
                  </li>
                  {/* <li>
                    <SelectCustom
                      value="restricted-area"
                      MenuProps={dropdownStyle.menuProps}
                      sx={dropdownStyle.sx}
                    >
                      <MenuItem
                        name="restricted-area"
                        value="restricted-area"
                        sx={{ ...dropdownStyle.menuItem, display: "none" }}
                        disabled
                        onClick={() =>
                          window.open(
                            "https://panel.tradingmillimetrico.com",
                            "_blank"
                          )
                        }
                      >
                        {t("menu.restricted_area")}
                      </MenuItem>
                      <MenuItem
                        name="1"
                        value="1"
                        sx={dropdownStyle.menuItem}
                        onClick={() =>
                          window.open(
                            "https://panel.tradingmillimetrico.com",
                            "_blank"
                          )
                        }
                      >
                        Alfa Advisor©
                      </MenuItem>
                      <MenuItem
                        name="Propfirm"
                        value="Propfirm"
                        sx={dropdownStyle.menuItem}
                        onClick={() =>
                          window.open(
                            "https://prop.tradingmillimetrico.com/",
                            "_blank"
                          )
                        }
                      >
                        Propfirm
                      </MenuItem>
                    </SelectCustom>
                  </li> */}
                  {/* <li className="border-2 border-primary-blue rounded-md hover:scale-110 hover-animation">
                    <a
                      aria-label="myfxbook"
                      href="#myfxbook"
                      className="flex py-2 px-8 items-center gap-3"
                    >
                      <picture>
                        <img
                          id="myfxbookimg"
                          src={`Images/${
                            theme === "dark"
                              ? "myfxbook-white.png"
                              : "myfxbook.png"
                          }`}
                          className="h-full w-full object-cover"
                          alt="fxbook"
                        />
                      </picture>
                      <img
                        src="Images/right-arrow.svg"
                        className="h-5 w-5 object-cover"
                        alt=""
                      />
                    </a>
                  </li> */}
                </ul>
              </div>

              <FormControl sx={{ minWidth: "20px", padding: "0" }}>
                <SelectCustom
                  onChange={handleChange}
                  value={country}
                  name="country"
                  inputProps={{
                    id: "open-select",
                  }}
                  MenuProps={theme === "dark" && langStyle.menuProps}
                  sx={theme === "dark" && langStyle.sx}
                >
                  {countries.map((option, key) => (
                    <MenuItem
                      name={"l"}
                      value={option.src}
                      key={key}
                      sx={theme === "dark" && langStyle.menuItem}
                    >
                      <img
                        width="30px"
                        height="30px"
                        src={option.src}
                        alt={option.label}
                      />{" "}
                    </MenuItem>
                  ))}
                </SelectCustom>
              </FormControl>
            </div>
            {/* <div className="">select</div> */}

            <div className="flex items-center gap-2  lg:absolute lg:-right-[3rem]">
              {/* dark mode button */}
              <div
                // onClick={setTheme(theme === "dark" ? "light" : "dark")}
                onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
                id="dark"
                className={`p-1 bg-primary-blue w-10 md:w-14 rounded-full cursor-pointer `}
              >
                <div className="rounded-full hover-animation cursor-pointer transform">
                  {/* sun */}
                  <div
                    id="sun"
                    className={`bg-white dark:bg-primary-dark_body hover-animation rounded-full w-4 h-4 md:w-6 md:h-6 flex justify-center items-center relative group ${
                      theme === "dark" ? "translate-x-[20px]" : ""
                    }`}
                  >
                    <img
                      id="sunImg"
                      src={`Images/${
                        theme === "dark" ? "moon.svg" : "sun.svg"
                      }`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {/*hamburgur menu */}
              <div onClick={toggleSidebar}>
                <svg
                  className="h-8 w-8 flex text-black dark:text-white lg:hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M2.75 12.25h10.5m-10.5-4h10.5m-10.5-4h10.5"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {showSidebar */}
      <div>
        {showSidebar && (
          <div className="fixed top-0 left-0 overflow-hidden w-full opacity-30 h-full bg-black  "></div>
        )}

        <div
          // className="fixed top-0 z-50 left-0 transition-all duration-500 ease-in-out dark:bg-primary-dark_body bg-[#F5FFFA] w-full md:w-full h-full overflow-hidden -translate-x-full"
          className={`fixed top-0 z-50 left-0 transition-all 
            duration-500 ease-in-out dark:bg-primary-dark_body
             bg-[#F5FFFA] w-full h-full overflow-hidden transform
             ${showSidebar ? "-translate-x-0" : "-translate-x-full"}`}
          id="sidebar"
        >
          <div className="absolute right-4 top-4">
            <span className="" onClick={toggleSidebar}>
              <svg
                className="h-8 w-8 text-black dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="currentColor"
                  d="m563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8L295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512L196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1l216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
                />
              </svg>
            </span>
          </div>
          <div className="flex flex-col overflow-y-scroll space-y-7 justify-center mt-10 ml-7">
            {/* <a href="#contact" >contact</a> */}

            <div className="">
              <a aria-label="index" href="./index.html">
                <picture>
                  <img src="Images/logo.png" alt="" className="w-24 h-24" />
                </picture>
              </a>
            </div>
            <ul className="space-y-7">
              <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="home"
                  href="#home"
                  className="nav-items"
                >
                  Home
                </a>
              </li>
              <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="chi siamo"
                  className="nav-items text-[16px]"
                  href="#who_we_are"
                >
                  Chi Siamo
                </a>
              </li>
              <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="myfixbook"
                  className="nav-items text-[16px]"
                  href="#myfixbook"
                >
                  Myfxbook
                </a>
              </li>
              <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="myfixbook"
                  className="nav-items text-[16px]"
                  href="#purchase"
                >
                  Prezzi
                </a>
              </li>
              <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="myfixbook"
                  className="nav-items text-[16px]"
                  href="#piano"
                >
                  Lavora con noi
                </a>
              </li>
              {/* <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="calculator"
                  className="nav-items text-[16px]"
                  href="#computer"
                >
                  Calcolatore
                </a>
              </li> 
              <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="lavora con noi"
                  className="nav-items text-[16px]"
                  href="#work_with_us"
                >
                  Lavora Con Noi
                </a>
              </li> */}
              <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="we_reviewed"
                  className="nav-items text-[16px]"
                  href="#we_reviewed"
                >
                  Recensioni
                </a>
              </li>
              <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="faq"
                  className="nav-items text-[16px]"
                  href="#faq"
                >
                  FAQ
                </a>
              </li>
              <li className="list-none">
                <a
                  onClick={toggleSidebar}
                  aria-label="contact"
                  className="nav-items text-[16px]"
                  href="#contacts"
                >
                  Contatti
                </a>
              </li>
              <li>
                <SelectCustom
                  value="restricted-area"
                  MenuProps={dropdownStyle.menuProps}
                  sx={{ ...dropdownStyle.sx, ml: -2, mt: -2 }}
                >
                  <MenuItem
                    name="restricted-area"
                    value="restricted-area"
                    sx={{ ...dropdownStyle.menuItem, display: "none" }}
                    disabled
                    onClick={() =>
                      window.open(
                        "https://panel.tradingmillimetrico.com",
                        "_blank"
                      )
                    }
                  >
                    {t("menu.restricted_area")}
                  </MenuItem>
                  <MenuItem
                    name="1"
                    value="1"
                    sx={dropdownStyle.menuItem}
                    onClick={() =>
                      window.open(
                        "https://panel.tradingmillimetrico.com",
                        "_blank"
                      )
                    }
                  >
                    Alfa Advisor©
                  </MenuItem>
                  <MenuItem
                    name="Propfirm"
                    value="Propfirm"
                    sx={dropdownStyle.menuItem}
                    onClick={() =>
                      window.open(
                        "https://prop.tradingmillimetrico.com/",
                        "_blank"
                      )
                    }
                  >
                    Propfirm
                  </MenuItem>
                </SelectCustom>
              </li>
            </ul>
            {/* <div className="inline-block">
              <ul>
                <li className="border-2 list-none rounded-md border-primary-blue inline-block hover-animation">
                  <a
                    onClick={toggleSidebar}
                    aria-label="myfxbook"
                    href="#myfxbook"
                    className="flex py-2 px-8 items-center gap-3"
                  >
                    <picture>
                      <img
                        src={`Images/${
                          theme === "dark"
                            ? "myfxbook-white.png"
                            : "myfxbook.png"
                        }`}
                        className="w-20 object-cover"
                        alt=""
                      />
                    </picture>
                    <img
                      src="images/right-arrow.svg"
                      className="h-6 w-6 object-cover"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

const SelectCustom = ({ theme, langStyle, onChange, children, ...other }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Select
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={onChange}
      {...other}
    >
      {children}
    </Select>
  );
};

export default Navbar;
