import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const ImageSlider = () => {
  const options = {
    items: 3,
    nav: true,
    loop: true,
    dots: false,
    margin: 10,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      },
      800: {
        items: 3,
      },
    },
    navText: [
      `<div class="prev-btn" style=' color: white;
    background-color: #37B5FF;
    padding: 10px;
    font-size:30px;
    position:absolute;
    top:40%;
    left:-5rem;
    border-radius:100px;
    height:40px;
    width:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    '
    > ← </div>`,
      `<div class="next-btn" style=' color: white;
    background-color: #37B5FF;
    padding: 10px;
    font-size:30px;
    position:absolute;
    top:40%;
    right:-5rem;
    border-radius:100px;
    height:40px;
    width:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    '
    > → </div>`,
    ],
  };

  const threeLogos = [
    {
      image: "facebook.png",
      link: "https://www.facebook.com/TradingMillimetrico/reviews/?ref=page_internal",
    },
    {
      image: "trustpilot.png",
      link: "https://it.trustpilot.com/review/tradingmillimetrico.com?languages=all",
    },
    {
      image: "google.png",
      link: "https://g.co/kgs/GPbHsr",
    },
  ];

  return (
    <>
      <div className="lg:px-52 p-5">
        <div className="">
          <OwlCarousel className="owl-theme mt-16" {...options}>
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0134.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0135-1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0136-1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0136.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0137-1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0138_1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0139.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0140.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0141.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0142.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0143_1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0145.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0146.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0147_1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0148.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0149_1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0150.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0152_1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0153.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0154.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0155.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0156.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0157.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0158.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0159.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0160.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0161_1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0163.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0165.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0167.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0168.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0169.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0170.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0171.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0172_1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0173_1_1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0189.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0192.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0193.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0194.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0195.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0196.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/IMG-20220331-WA0197_1_1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/WhatsApp-Image-2022-04-22-at-22.04.13.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/WhatsApp-Image-2022-04-22-at-22.04.14-1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/WhatsApp-Image-2022-04-22-at-22.04.14.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/WhatsApp-Image-2022-04-22-at-22.04.15-1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/WhatsApp-Image-2022-04-22-at-22.04.15.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/WhatsApp-Image-2022-04-22-at-22.26.37.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/WhatsApp-Image-2022-04-22-at-22.04.15-1.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>{" "}
            <picture>
              <img
                src="Images/Slider/WhatsApp-Image-2022-04-22-at-22.04.15.jpg"
                className="block w-full h-[80vh]"
                alt=""
                loading="lazy"
              />
            </picture>
          </OwlCarousel>
        </div>

        {/* three logos */}
        <div
          className="grid grid-cols-1 sm:grid-cols-3 justify-between mt-16 gap-10
        "
        >
          {threeLogos.map((item, index) => (
            <div
              className="bg-white w-full grid place-items-center rounded-2xl p-4"
              key={index}
            >
              <a
                target="_blank"
                href={item.link}
                className="w-full grid place-items-center h-full  overflow-hidden"
              >
                <img
                  src={`Images/${item.image}`}
                  alt=""
                  className=" h-full object-contain w-[60%]"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
